import styled from 'styled-components';

export const Content = styled.div`
  padding: 2rem;
  border-radius: 0.4rem;
  background: var(--color-primary);

  form {
    div.group {
      display: flex;
      flex-direction: column;

      div {
        width: 100%;
        margin-bottom: 0.5rem;
      }
    }

    div.cancel {
      margin-left: 0;

      button {
        background: var(--color-red);
        &:hover {
          opacity: var(--opacity-semiOpaque);
        }
      }
    }
  }

  @media (min-width: 1180px) {
    width: 80%;

    form {
      div.group {
        display: flex;
        padding-bottom: 0;
      }

      div.group-button {
        display: flex;

        div {
          width: 100%;
        }

        div.cancel {
          width: 100%;
          display: flex;
          justify-content: flex-end;

          button {
            width: 50%;
          }
        }
      }
    }
  }
`;
