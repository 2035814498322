import styled from 'styled-components';

export const Container = styled.div`
  padding: 0.5rem 2rem;
  border-radius: 0.4rem;
  border-left: 0.1rem solid var(--color-white);
  background: var(--color-primary);

  div.login {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding-top: 1rem;
    margin-top: 1rem;
    border-top: 0.1rem solid var(--color-tertiary);

    div.version {
      display: flex;

      p {
        margin-right: 1rem;
        padding: 0.2rem 0.4rem;
        border-radius: 0.4rem;

        background: ${props =>
          props.beta ? 'var(--color-tertiary)' : 'var(--color-background)'};

        opacity: var(--opacity-semiOpaque);
        color: var(--color-white);

        font-size: var(--fontSizes-lg);
      }

      p.pro {
        background: ${props =>
          props.pro ? 'var(--color-tertiary)' : 'var(--color-background)'};
      }
    }

    span {
      box-shadow: rgb(232 241 242 / 10%) 0 0.5rem 2rem;
      background: rgb(19 41 61 / 10%);
      opacity: var(--opacity-semiOpaque);

      font-size: var(--fontSizes-xl);
    }
  }

  &:hover {
    opacity: var(--opacity-semiOpaque);
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  div.validate {
    display: flex;
    align-items: center;
    justify-content: center;

    div {
      margin-left: 1rem;
      text-align: right;

      span {
        display: block;
        margin-bottom: 0.2rem;
        font-size: var(--fontSizes-xl);
        color: var(--color-blue);
      }

      span.price {
        color: var(--color-green);
      }

      p {
        color: var(--color-white);

        font-size: var(--fontSizes-2xl);
      }
    }
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;

  div {
    strong {
      display: block;
      margin-bottom: 0.2rem;
      font-size: var(--fontSizes-2xl);
      color: var(--color-white);

      max-width: 20ch;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    span {
      opacity: var(--opacity-intense);
      color: var(--color-white);

      font-size: var(--fontSizes-2xl);
    }
  }

  @media (min-width: 1180px) {
    div strong {
      max-width: 50ch;
    }
  }
`;
