import styled from 'styled-components';

export const Scroll = styled.div`
  div.buttons {
    display: flex;
    align-items: center;

    margin-right: -1rem;
  }

  div.loading {
    display: flex;
    justify-content: center;
  }

  div.search {
    div + div {
      margin-top: -1rem;
      margin-bottom: 2rem;
    }
  }

  ul {
    display: grid;
    gap: 0.5rem;

    p {
      display: flex;
      justify-content: center;

      font-size: var(--fontSizes-2xl);
      color: var(--color-white);
      opacity: var(--opacity-intense);
    }
  }

  @media (min-width: 700px) {
    div.search {
      display: flex;
      align-items: center;
      justify-content: center;

      margin-bottom: 2rem;

      div {
        width: 100%;
      }

      div + div {
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 1rem;
      }
    }
  }
`;
