import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  font-size: var(--fontSizes-2xl);
  color: var(--color-white);
  background: var(--color-primary);

  min-width: 10rem;
  height: 6rem;
  border-radius: 0.5rem;

  -webkit-box-align: center;
  -webkit-box-pack: justify;

  strong {
    padding-top: 0.8rem;
  }
`;
