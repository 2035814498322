import React from 'react';
import { Link } from 'react-router-dom';

import { Div } from './styles';

export default function MenuButton({ to, focus, className, children }) {
  return (
    <Div>
      <Link
        to={to}
        className={className}
        style={
          focus !== undefined
            ? { color: 'var(--color-background)', borderLeft: 'none' }
            : {}
        }
      >
        {children}
      </Link>
    </Div>
  );
}
