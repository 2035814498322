import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import CircularProgress from '@material-ui/core/CircularProgress';

import { Aside, Container, Main } from '~/components/Body';
import InputText from '~/components/InputText';
import Tenant from '~/components/Tenant';
import Title from '~/components/Title';
import UserLogout from '~/components/UserLogout';
import api from '~/services/api';
import { updateTenantRequest } from '~/store/modules/tenant/actions';
import { ToastError } from '~/utils/toast';

import Menu from '../Menu';
import { Scroll } from './styles';

export default function TenantActive() {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [logout, setLogout] = useState(false);
  const [message, setMessage] = useState('');

  const [tenants, setTenants] = useState([]);

  const [search, setSearch] = useState('');

  const filteredTenants = search
    ? tenants.filter(tenant =>
        tenant.nickname.toLowerCase().includes(search.toLowerCase())
      )
    : tenants;

  try {
    useEffect(() => {
      async function loadTenants() {
        const response = await api
          .get('/admin/tenants-actives')
          .catch(async res => {
            try {
              if (res.response.data.error === 'User disabled') {
                setMessage(
                  'Autenticação falhou. Entre em contato com seu administrador'
                );

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Token not provided') {
                setMessage('Autenticação falhou, refaça seu login');

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Token invalid') {
                setMessage('Autenticação inválida, refaça seu login');

                setLogout(true);

                return;
              }
            } catch (error) {
              ToastError({ title: 'Erro ao carregar os dados' });

              setLoading(false);
            }
          });

        if (response === undefined) {
          setLoading(false);

          return;
        }

        setTenants(response.data);

        setLoading(false);
      }

      loadTenants();
    }, []);
  } catch (error) {
    ToastError({ title: 'Erro ao carregar os dados' });
  }

  async function handleEdit(id) {
    dispatch(updateTenantRequest(id));
  }

  return (
    <Container>
      <Aside>
        <Menu tenantActived="tenantActived" />
      </Aside>

      <Main>
        <Title title="Contas ativas" count={`[ ${filteredTenants.length} ]`} />

        <Scroll>
          <InputText
            name="searchSong"
            placeholder="Encontre por Conta"
            value={search}
            onChange={event => setSearch(event.target.value)}
          />

          {loading ? (
            <div className="loading">
              <CircularProgress style={{ color: 'var(--color-secondary)' }} />
            </div>
          ) : (
            <ul className="ul">
              {filteredTenants.map(tenant => (
                <Tenant
                  key={tenant.id}
                  tenant={tenant}
                  onEdit={() => handleEdit(tenant.id)}
                />
              ))}
              {tenants.length === 0 && <p>Nenhuma conta encontrada</p>}
            </ul>
          )}
        </Scroll>
      </Main>

      {logout && <UserLogout message={message} />}
    </Container>
  );
}
