import React, { useEffect, useState } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import Swal from 'sweetalert2/src/sweetalert2';

import { Aside, Container, Main } from '~/components/Body';
import Helpdesks from '~/components/Helpdesk';
import Title from '~/components/Title';
import UserLogout from '~/components/UserLogout';
import api from '~/services/api';
import { ToastError } from '~/utils/toast';

import Menu from '../Dashboard/Menu';
import { Scroll } from './styles';

export default function Helpdesk() {
  const [loading, setLoading] = useState(true);
  const [logout, setLogout] = useState(false);
  const [message, setMessage] = useState('');

  const [helpdesks, setHelpdesks] = useState([]);

  try {
    useEffect(() => {
      async function loadHelpdesks() {
        const response = await api.get('/admin/helpdesks').catch(async res => {
          try {
            if (res.response.data.error === 'Token not provided') {
              setMessage('Autenticação falhou, refaça seu login');

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Token invalid') {
              setMessage('Autenticação inválida, refaça seu login');

              setLogout(true);

              return;
            }
          } catch (error) {
            ToastError({ title: 'Erro ao carregar os dados' });

            setLoading(false);
          }
        });

        if (response === undefined) {
          setLoading(false);

          return;
        }

        setHelpdesks(response.data);

        setLoading(false);
      }

      loadHelpdesks();
    }, []);
  } catch (error) {
    ToastError({ title: 'Erro ao carregar os dados' });
  }

  async function handleDelete(id) {
    Swal.fire({
      title: 'Você tem certeza?',
      text: 'Você não poderá reverter isso!',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: 'var(--color-blue)',
      cancelButtonColor: 'var(--color-red)',
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar',
    }).then(async result => {
      if (result.value) {
        try {
          const dataResponse = await api
            .delete(`/admin/helpdesk/${id}`)
            .catch(async res => {
              try {
                if (res.response.data.error === 'User without permission') {
                  setMessage(
                    'Você não possui permissão para este tipo de operação'
                  );

                  setLogout(true);

                  return;
                }

                if (res.response.data.error === 'Token not provided') {
                  setMessage('Autenticação falhou, refaça seu login');

                  setLogout(true);

                  return;
                }

                if (res.response.data.error === 'Token invalid') {
                  setMessage('Autenticação inválida, refaça seu login');

                  setLogout(true);

                  return;
                }
              } catch (error) {
                ToastError({ title: 'Erro ao excluir' });

                setLoading(false);
              }
            });

          if (dataResponse === undefined) return;

          Swal.fire('Excluído!', 'Helpdesk excluído', 'success');

          setHelpdesks(helpdesks.filter(helpdesk => helpdesk.id !== id));
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Algo deu errado!',
          });
        }
      }
    });
  }

  return (
    <Container>
      <Aside>
        <Menu helpdesk="helpdesk" />
      </Aside>

      <Main>
        <Title title="Helpdesk" count={`[ ${helpdesks.length} ]`} />

        <Scroll>
          {loading ? (
            <div className="loading">
              <CircularProgress style={{ color: 'var(--color-secondary)' }} />
            </div>
          ) : (
            <ul>
              {helpdesks.map(helpdesk => (
                <Helpdesks
                  key={helpdesk.id}
                  helpdesk={helpdesk}
                  onDelete={() => handleDelete(helpdesk.id)}
                />
              ))}
              {helpdesks.length === 0 && <p>Nenhuma dúvida encontrada</p>}
            </ul>
          )}
        </Scroll>
      </Main>

      {logout && <UserLogout message={message} />}
    </Container>
  );
}
