import React from 'react';

import PropTypes from 'prop-types';

import { Content, Wrapper } from './styles';

export default function AuthLayout({ children }) {
  return (
    <Wrapper>
      <Content> {children}</Content>
    </Wrapper>
  );
}

AuthLayout.protoTypes = {
  children: PropTypes.element.isRequired,
};
