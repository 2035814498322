import React from 'react';
import { MdEdit } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { addMonths, format, isBefore, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';

import { formatPrice } from '~/utils/format';

import { Container, Content, Header } from './styles';

export default function TenantActive({ tenant, onEdit }) {
  const { permission } = useSelector(state => state.user.profile);

  const parsedDate = parseISO(tenant.available);
  const parsedDateLogin = parseISO(
    tenant.login[0] ? tenant.login[0].created_at : new Date().toISOString()
  );

  const formattedAvailable = format(parsedDate, 'yyyy-MM-dd');
  const formattedNewDate = format(new Date(), 'yyyy-MM-dd');

  const formattedAvailableLogin = format(
    addMonths(new Date(parsedDateLogin), 3),
    'yyyy-MM-dd'
  );

  const result = isBefore(
    new Date(formattedAvailable),
    new Date(formattedNewDate)
  );

  const resultLogin = isBefore(
    new Date(formattedAvailableLogin),
    new Date(formattedNewDate)
  );

  const formattedDate = format(parsedDate, "dd 'de' MMM 'de' yyyy'", {
    locale: pt,
  });

  const formattedDateLogin = format(parsedDateLogin, 'dd/MM/yyyy HH:mm');

  const formattedPrice = formatPrice(tenant.people * tenant.price);

  return (
    <Container beta={tenant.beta} pro={tenant.pro}>
      <Content>
        <Header>
          <div>
            <strong>{tenant.name}</strong>
            <span>
              {tenant.nickname} [ {tenant.usersAll.length}/{tenant.people} ]
            </span>
          </div>
        </Header>

        <div className="validate">
          <div>
            <span className="price">{formattedPrice}</span>

            <p
              style={{
                color: `${tenant.pro && result ? 'var(--color-red)' : ''}`,
                fontWeight: `${tenant.pro && result ? 'bold' : ''}`,
              }}
            >
              {formattedDate}
            </p>
          </div>

          {permission === true ? (
            <div>
              <Link
                to="/tenant/tenant-update"
                title="Alterar cadastro"
                onClick={onEdit}
              >
                <MdEdit size={20} />
              </Link>
            </div>
          ) : (
            ''
          )}
        </div>
      </Content>

      <div className="login">
        <div className="version">
          <p className="pro">Pro</p>

          <p>Beta</p>
        </div>

        <span
          style={{
            color: `${resultLogin ? 'var(--color-red)' : ''}`,
            fontWeight: `${resultLogin ? 'bold' : ''}`,
          }}
        >
          {tenant.login[0] ? formattedDateLogin : 'Nenhum login'}
        </span>
      </div>
    </Container>
  );
}
