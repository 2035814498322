import React from 'react';
import { Switch } from 'react-router-dom';

import Password from '~/pages/Account/Password';
import Profile from '~/pages/Account/Profile';
import Forgot from '~/pages/Auth/Forgot';
import SignIn from '~/pages/Auth/SignIn';
import Dashboard from '~/pages/Dashboard/Home';
import Feedback from '~/pages/Feedback';
import Helpdesk from '~/pages/Helpdesk';
import ChangelogList from '~/pages/News/Changelog/ChangelogList';
import ChangelogNew from '~/pages/News/Changelog/ChangelogNew';
import ChangelogUpdate from '~/pages/News/Changelog/ChangelogUpdate';
import TenantActive from '~/pages/Tenant/TenantActive';
import TenantCanceled from '~/pages/Tenant/TenantCanceled';
import TenantDisabled from '~/pages/Tenant/TenantDisabled';
import TenantInfoHistorys from '~/pages/Tenant/TenantInfo/Historys';
import TenantInfoLogins from '~/pages/Tenant/TenantInfo/Logins';
import TenantNew from '~/pages/Tenant/TenantNew';
import TenantUpdate from '~/pages/Tenant/TenantUpdate';
import UserActive from '~/pages/User/UserActive';
import UserDisabled from '~/pages/User/UserDisabled';
import UserNew from '~/pages/User/UserNew';
import UserUpdate from '~/pages/User/UserUpdate';

import Route from './Route';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />

      <Route path="/dashboard" component={Dashboard} isPrivate />
      <Route path="/forgot" component={Forgot} />

      <Route path="/account/profile" component={Profile} isPrivate />
      <Route path="/account/password" component={Password} isPrivate />

      <Route path="/user/user-active" component={UserActive} isPrivate />
      <Route path="/user/user-disabled" component={UserDisabled} isPrivate />
      <Route path="/user/user-new" component={UserNew} isPrivate />
      <Route path="/user/user-update" component={UserUpdate} isPrivate />

      <Route path="/tenant/tenant-active" component={TenantActive} isPrivate />
      <Route
        path="/tenant/tenant-disabled"
        component={TenantDisabled}
        isPrivate
      />
      <Route
        path="/tenant/tenant-canceled"
        component={TenantCanceled}
        isPrivate
      />
      <Route path="/tenant/tenant-new" component={TenantNew} isPrivate />
      <Route path="/tenant/tenant-update" component={TenantUpdate} isPrivate />
      <Route
        path="/tenant/tenant-info-logins"
        component={TenantInfoLogins}
        isPrivate
      />
      <Route
        path="/tenant/tenant-info-historys"
        component={TenantInfoHistorys}
        isPrivate
      />

      <Route path="/news/changelog-list" component={ChangelogList} isPrivate />
      <Route path="/news/changelog-new" component={ChangelogNew} isPrivate />
      <Route
        path="/news/changelog-update"
        component={ChangelogUpdate}
        isPrivate
      />

      <Route path="/feedbacks" component={Feedback} isPrivate />

      <Route path="/helpdesks" component={Helpdesk} isPrivate />

      <Route
        path="/"
        component={() => (
          <p>
            <strong>Erro: 404</strong> <br /> Ooooops, essa página não existe.!
          </p>
        )}
      />
    </Switch>
  );
}
