import styled from 'styled-components';

export const Scroll = styled.div`
  div.loading {
    display: flex;
    justify-content: center;
  }

  ul {
    display: grid;
    gap: 0.5rem;

    p {
      display: flex;
      justify-content: center;
      font-size: var(--fontSizes-2xl);
      color: var(--color-tertiary);
    }
  }
`;
