import React from 'react';

import { Container } from './styles';

export default function InfoCount({ count, icon }) {
  return (
    <Container>
      {icon}
      <strong>{count}</strong>
    </Container>
  );
}
