import styled from 'styled-components';

export const Container = styled.div`
  padding: 2rem;
  border-radius: 0.4rem;
  border-left: 0.1rem solid var(--color-white);
  background: var(--color-primary);

  div.version {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 2rem;

    strong {
      font-size: var(--fontSizes-4xl);
      color: var(--color-white);
    }

    a {
      font-size: var(--fontSizes-2xl);
      color: var(--color-white);
      &:hover {
        opacity: var(--opacity-medium);
      }
    }
  }

  div.div-player {
    padding-bottom: 2rem;

    div {
      width: 100% !important;
      height: 18rem !important;

      div.react-player__play-icon {
        width: 1.4rem !important;
        height: 1.4rem !important;
      }
    }
  }

  div {
    pre {
      font-size: var(--fontSizes-2xl);
      font-family: 'Roboto', sans-serif;
      text-align: justify;
      line-height: 3rem;
      opacity: var(--opacity-semiOpaque);

      white-space: pre-wrap;
      word-break: break-word;
    }

    strong,
    span,
    p,
    a,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    pre,
    code,
    small {
      -webkit-touch-callout: inherit;
      -webkit-user-select: text;
      -moz-user-select: text;
      -ms-user-select: text;
      user-select: text;
    }
  }

  div span {
    display: flex;
    justify-content: flex-end;

    font-size: var(--fontSizes-lg);
    color: var(--color-white);
    opacity: var(--opacity-medium);
  }

  @media (min-width: 1180px) {
    div.div-player {
      div {
        height: 37rem !important;
      }
    }
  }
`;
