import React, { useState } from 'react';
import Switch from 'react-switch';

import CircularProgress from '@material-ui/core/CircularProgress';
import { Form } from '@rocketseat/unform';
import * as Yup from 'yup';

import { Aside, Container, Main } from '~/components/Body';
import InputText from '~/components/InputText';
import SubmitButton from '~/components/SubmitButton';
import Title from '~/components/Title';
import UserLogout from '~/components/UserLogout';
import api from '~/services/api';
import { cpfMask, phoneMask } from '~/utils/inputMask';
import { ToastError, ToastSuccess } from '~/utils/toast';

import Menu from '../Menu';
import { Content, Label } from './styles';

const schema = Yup.object().shape({
  name: Yup.string()
    .min(10, 'Nome completo deve ter no mínimo 10 caracteres')
    .max(50, 'Nome completo deve ter no máximo 50 caracteres'),
  nameuser: Yup.string()
    .min(3, 'Nome de usuário deve ter no mínimo 3 caracteres')
    .max(20, 'Nome de usuário deve ter no máximo 20 caracteres'),
  email: Yup.string()
    .email('Insira um e-mail válido')
    .min(7, 'E-mail deve ter no mínimo 7 caracteres')
    .max(50, 'E-mail deve ter no máximo 50 caracteres'),
  password: Yup.string(),
  confirmPassword: Yup.string()
    .min(4, 'Confirme a senha deve ter no mínimo 4 caracteres')
    .max(20, 'Confirme a senha deve ter no máximo 20 caracteres')
    .oneOf([Yup.ref('password'), null], 'Senhas não conferem'),
  document: Yup.string().length(14, 'CPF deve ser preenchido apenas números'),
  phone: Yup.string().length(16, 'Celular deve ser preenchido apenas números'),
  active: Yup.boolean(),
  permission: Yup.boolean(),
});

export default function UserNew() {
  const [loading, setLoading] = useState(false);
  const [logout, setLogout] = useState(false);
  const [message, setMessage] = useState('');

  const [name, setName] = useState('');
  const [nameuser, setNameuser] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [document, setDocument] = useState('');
  const [phone, setPhone] = useState('');
  const [active, setActive] = useState(true);
  const [permission, setPermission] = useState(false);

  async function handleSubmit() {
    try {
      setLoading(true);

      const data = await api
        .post('/admin/users', {
          name,
          nameuser,
          email,
          password,
          document,
          phone,
          active,
          permission,
        })
        .catch(async res => {
          try {
            if (res.response.data.error === 'Validation fails') {
              ToastError({
                title:
                  'Seus dados foram recusados. Verifique-os e tente novamente',
              });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'User disabled') {
              setMessage(
                'Autenticação falhou. Entre em contato com seu administrador'
              );

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'User without permission') {
              setMessage(
                'Você não possui permissão para este tipo de operação'
              );

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'User name already exists') {
              ToastError({ title: 'Nome de usuário já existe' });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'Email already exists') {
              ToastError({ title: 'E-mail já existe' });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'Document already exists') {
              ToastError({ title: 'CPF já existe' });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'CPF is invalid') {
              ToastError({ title: 'CPF é inválido' });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'Id already exists') {
              ToastError({ title: 'Recarregue a pagina e tente novamente' });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'Token not provided') {
              setMessage('Autenticação falhou, refaça seu login');

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Token invalid') {
              setMessage('Autenticação inválida, refaça seu login');

              setLogout(true);

              return;
            }
          } catch (error) {
            ToastError({ title: 'Erro ao adicionar, verifique os dados' });

            setLoading(false);
          }
        });

      if (data === undefined) {
        setLoading(false);

        return;
      }

      ToastSuccess({ title: 'Usuário adicionado' });

      setName('');
      setNameuser('');
      setEmail('');
      setPassword('');
      setDocument('');
      setPhone('');
      setActive(true);
      setPermission(false);

      setLoading(false);
    } catch (error) {
      setLoading(false);

      ToastError({ title: 'Erro ao adicionar, verifique os dados' });
    }
  }

  return (
    <Container>
      <Aside>
        <Menu userNew="userNew" />
      </Aside>

      <Main>
        <Title title="Adicionar usuário" />

        <Content>
          <Form schema={schema} onSubmit={handleSubmit}>
            <div className="group">
              <div className="name">
                <InputText
                  label="Nome completo"
                  name="name"
                  value={name}
                  onChange={event => setName(event.target.value)}
                />
              </div>

              <div className="nameuser">
                <InputText
                  label="Nome de usuário"
                  name="nameuser"
                  value={nameuser}
                  onChange={event =>
                    setNameuser(
                      event.target.value.toLowerCase().replace(/\s+/g, '')
                    )
                  }
                />
              </div>
            </div>

            <div className="group">
              <div className="email">
                <InputText
                  label="E-mail"
                  name="email"
                  type="email"
                  value={email}
                  onChange={event => setEmail(event.target.value)}
                />
              </div>

              <div className="password">
                <InputText
                  label="Senha"
                  name="password"
                  type="password"
                  autoComplete="cc-csc"
                  value={password}
                  onChange={event => setPassword(event.target.value)}
                />
              </div>

              <div className="confirm-password">
                <InputText
                  label="Confirme a senha"
                  name="confirmPassword"
                  type="password"
                  autoComplete="cc-csc"
                />
              </div>
            </div>

            <div className="group">
              <div className="document">
                <InputText
                  label="CPF"
                  name="document"
                  value={document}
                  onChange={event => setDocument(cpfMask(event.target.value))}
                />
              </div>

              <div className="phone">
                <InputText
                  label="Celular"
                  name="phone"
                  value={phone}
                  onChange={event => setPhone(phoneMask(event.target.value))}
                />
              </div>
            </div>

            <div className="group-switch">
              <div>
                <Label>Administrador</Label>
                <div className="permission">
                  <Switch
                    height={15}
                    width={40}
                    handleDiameter={20}
                    onColor="#247BA0"
                    offColor="#13293D"
                    onChange={() => setPermission(!permission)}
                    checked={permission}
                  />
                </div>
              </div>

              <div>
                <Label>Ativo</Label>
                <div className="permission">
                  <Switch
                    height={15}
                    width={40}
                    handleDiameter={20}
                    onColor="#247BA0"
                    offColor="#13293D"
                    onChange={() => setActive(!active)}
                    checked={active}
                  />
                </div>
              </div>
            </div>

            <div className="group">
              <div>
                <SubmitButton type="submit" disabled={loading}>
                  {loading ? (
                    <CircularProgress
                      size={24}
                      style={{ color: 'var(--color-white)' }}
                    />
                  ) : (
                    'Salvar'
                  )}
                </SubmitButton>
              </div>

              <div className="cancel">
                <SubmitButton
                  type="button"
                  onClick={() => window.history.back()}
                >
                  Cancelar
                </SubmitButton>
              </div>
            </div>
          </Form>
        </Content>
      </Main>

      {logout && <UserLogout message={message} />}
    </Container>
  );
}
