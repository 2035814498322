import React from 'react';

import { Button } from './styles';

export default function SubMenuButton({ focus, type, onClick, children }) {
  return (
    <Button
      style={focus && { color: 'var(--color-background)' }}
      type={type}
      onClick={onClick}
    >
      {children}
    </Button>
  );
}
