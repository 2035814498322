import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;

  height: 100vh;
  width: 100vw;

  div.logo {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 90%;
    background: var(--color-secondary);
    opacity: var(--opacity-semiOpaque);

    img {
      height: 25rem;
      width: 25rem;
    }

    @media (max-width: 900px) {
      display: none;
    }
  }

  div.signin {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 500px;

    @media (max-width: 900px) {
      width: 100vw;
    }

    div.title {
      display: flex;
      align-items: center;

      width: 90%;

      img {
        height: 3rem;
        width: 3rem;
      }

      h1 {
        margin-left: 1rem;
        color: var(--color-white);
      }
    }

    div.form {
      width: 90%;

      form {
        display: flex;
        flex-direction: column;
        margin: 3rem 0;

        h2 {
          margin-bottom: 4rem;

          font-size: var(--fontSizes-4xl);
          color: var(--color-white);
          opacity: var(--opacity-semiOpaque);
        }

        input {
          width: 100%;
          height: 4.4rem;
          padding: 0 1.5rem;
          border-radius: 1.1rem;
          border: 0.1rem solid var(--color-tertiary);
          background: var(--color-background);
          color: var(--color-white);

          margin: 0 0 1rem;

          &::placeholder {
            color: var(--color-white);
            opacity: var(--opacity-intense);
          }

          &:focus {
            border: 0.1rem solid var(--color-white);
          }
        }

        span {
          margin: 0 0 1rem;
          align-self: flex-start;
          color: var(--color-mandatory);
        }

        button {
          div {
            display: flex;
            align-items: center;
            justify-content: center;

            div {
              position: absolute;

              p {
                opacity: var(--opacity-intense);
              }
            }
          }

          width: 100%;
          height: 4.4rem;
          border-radius: 1.1rem;
          border: 0;
          background: var(--color-secondary);

          font-size: var(--fontSizes-2xl);
          font-weight: bold;
          color: var(--color-white);

          margin-top: 2rem;
          &:hover {
            opacity: var(--opacity-semiOpaque);
          }
        }
      }

      a {
        margin: 1rem 0 0;
        font-size: var(--fontSizes-2xl);
        text-align: start;
        color: var(--color-secondary);
        &:hover {
          opacity: var(--opacity-semiOpaque);
        }
      }
    }
  }
`;
