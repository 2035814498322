import styled from 'styled-components';

export const Content = styled.div`
  padding: 2rem;
  border-radius: 0.4rem;
  background: var(--color-primary);

  form {
    div.group {
      display: block;
      padding-bottom: 1rem;

      div.name,
      div.people,
      div.price,
      div.responsible,
      div.email,
      div.password,
      div.responsible_document,
      div.zip_code,
      div.number,
      div.district,
      div.city {
        margin-bottom: 1rem;
      }

      div {
        width: 100%;

        input.disabled {
          color: var(--color-yellow);

          cursor: not-allowed;
        }
      }

      div.cancel {
        margin-left: 0;

        button {
          background: var(--color-red);
          &:hover {
            opacity: var(--opacity-semiOpaque);
          }
        }
      }
    }

    hr {
      border: 0;
      height: 0.1rem;
      background-color: var(--color-tertiary);

      margin: 0.5rem 0;
    }

    h2 {
      margin-bottom: 4rem;
    }

    div.group-switch {
      display: flex;
      align-items: center;

      div {
        width: 100%;
      }

      div.permission {
        padding: 0.5rem 0 0 0;
      }
    }
  }

  @media (min-width: 1180px) {
    form {
      div.group {
        display: flex;
        padding-bottom: 0;

        div.name,
        div.people,
        div.price,
        div.responsible,
        div.email,
        div.password,
        div.responsible_document,
        div.zip_code,
        div.number,
        div.district,
        div.city {
          margin-right: 1rem;
        }

        div.nickname {
          width: 50%;
        }

        div.document,
        div.nameuser,
        div.password,
        div.confirm-password {
          width: 40%;
        }

        div.people,
        div.price,
        div.zip_code,
        div.number,
        div.state {
          width: 30%;
        }

        div.cancel {
          display: flex;
          justify-content: flex-end;

          button {
            width: 50%;
          }
        }
      }
    }
  }
`;

export const Label = styled.label``;
