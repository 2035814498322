import React from 'react';
import {
  MdAutorenew,
  MdCloudDone,
  MdFeedback,
  MdGroup,
  MdHome,
  MdLiveHelp,
  MdPerson,
} from 'react-icons/md';
import { useSelector } from 'react-redux';

import MenuButton from '~/components/MenuButton';

import { Content } from './styles';

export default function Menu({ home, feedback, helpdesk }) {
  const { permission } = useSelector(state => state.user.profile);

  return (
    <Content permission={!permission}>
      <div>
        <MenuButton to="/dashboard" focus={home}>
          <MdHome size={18} /> Home
        </MenuButton>

        <MenuButton to="/user/user-active">
          <MdGroup size={18} /> Usuários
        </MenuButton>

        <MenuButton to="/account/profile">
          <MdPerson size={18} /> Perfil
        </MenuButton>

        <MenuButton to="/tenant/tenant-active">
          <MdCloudDone size={18} /> Contas
        </MenuButton>

        <MenuButton to="/news/changelog-list">
          <MdAutorenew size={18} /> Changelog
        </MenuButton>

        <MenuButton to="/feedbacks" focus={feedback}>
          <MdFeedback size={18} /> Feedbacks
        </MenuButton>

        <MenuButton to="/helpdesks" focus={helpdesk}>
          <MdLiveHelp size={18} /> Helpdesk
        </MenuButton>
      </div>
    </Content>
  );
}
