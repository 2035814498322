import React from 'react';
import { MdHome, MdPerson, MdPersonAdd, MdPersonOutline } from 'react-icons/md';
import { useSelector } from 'react-redux';

import MenuButton from '~/components/MenuButton';

import { Content } from './styles';

export default function Menu({ userActive, userDisabled, userNew }) {
  const { permission } = useSelector(state => state.user.profile);

  return (
    <Content permission={!permission}>
      <div>
        <MenuButton to="/dashboard">
          <MdHome size={18} /> Home
        </MenuButton>

        <MenuButton to="/user/user-active" focus={userActive}>
          <MdPerson size={18} /> Ativos
        </MenuButton>

        <MenuButton
          className="permission"
          to="/user/user-disabled"
          focus={userDisabled}
        >
          <MdPersonOutline size={18} /> Desativados
        </MenuButton>

        <MenuButton className="permission" to="/user/user-new" focus={userNew}>
          <MdPersonAdd size={18} /> Adicionar
        </MenuButton>
      </div>
    </Content>
  );
}
