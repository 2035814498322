import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import CircularProgress from '@material-ui/core/CircularProgress';
import { Form } from '@rocketseat/unform';
import * as Yup from 'yup';

import { Aside, Container, Main } from '~/components/Body';
import InputText from '~/components/InputText';
import SubmitButton from '~/components/SubmitButton';
import Title from '~/components/Title';
import UserLogout from '~/components/UserLogout';
import api from '~/services/api';
import { ToastError, ToastSuccess } from '~/utils/toast';

import Menu from '../../Menu';
import { Content } from './styles';

const schema = Yup.object().shape({
  version: Yup.string()
    .min(3, 'Descrição deve ter no mínimo 3 caracteres')
    .max(50, 'Descrição deve ter no máximo 50 caracteres'),
  change: Yup.string()
    .min(10, 'Alterações deve ter no mínimo 10 caracteres')
    .max(1200, 'Alterações deve ter no máximo 1200 caracteres'),
  link: Yup.string().max(254, 'URL YouTube deve ter no máximo 254 caracteres'),
});

export default function ChangelogNew() {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [logout, setLogout] = useState(false);
  const [message, setMessage] = useState('');

  const [version, setVersion] = useState('');
  const [change, setChange] = useState('');
  const [link, setLink] = useState('');

  async function handleSubmit() {
    try {
      setLoading(true);

      const data = await api
        .post('/admin/changelogs', {
          version,
          change,
          link,
        })
        .catch(async res => {
          try {
            if (res.response.data.error === 'Validation fails') {
              ToastError({
                title:
                  'Seus dados foram recusados. Verifique-os e tente novamente',
              });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'User disabled') {
              setMessage(
                'Autenticação falhou. Entre em contato com seu administrador'
              );

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'User without permission') {
              setMessage(
                'Você não possui permissão para este tipo de operação'
              );

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Id already exists') {
              ToastError({ title: 'Recarregue a pagina e tente novamente' });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'Token not provided') {
              setMessage('Autenticação falhou, refaça seu login');

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Token invalid') {
              setMessage('Autenticação inválida, refaça seu login');

              setLogout(true);

              return;
            }
          } catch (error) {
            ToastError({ title: 'Erro ao adicionar, verifique os dados' });

            setLoading(false);
          }
        });

      if (data === undefined) {
        setLoading(false);

        return;
      }

      ToastSuccess({ title: 'Changelog adicionado' });

      setVersion('');
      setChange('');
      setLink('');

      setLoading(false);
    } catch (error) {
      setLoading(false);

      ToastError({ title: 'Erro ao adicionar, verifique os dados' });
    }
  }

  return (
    <Container>
      <Aside>
        <Menu changelogNew="changelogNew" />
      </Aside>

      <Main>
        <Title title="Adicionar changelog" />

        <Content>
          <Form schema={schema} onSubmit={handleSubmit}>
            <div className="version">
              <InputText
                label="Descrição"
                name="version"
                value={version}
                onChange={event => setVersion(event.target.value)}
              />
            </div>

            <div className="group">
              <div className="link">
                <InputText
                  label="URL YouTube"
                  name="link"
                  value={link}
                  onChange={event => setLink(event.target.value)}
                />
              </div>
            </div>

            <div className="change">
              <InputText
                label="Alterações"
                name="change"
                value={change}
                onChange={event => setChange(event.target.value)}
                multiline
              />
            </div>

            <div className="group">
              <div>
                <SubmitButton type="submit" disabled={loading}>
                  {loading ? (
                    <CircularProgress
                      size={24}
                      style={{ color: 'var(--color-white)' }}
                    />
                  ) : (
                    'Salvar'
                  )}
                </SubmitButton>
              </div>

              <div className="cancel">
                <SubmitButton
                  type="button"
                  onClick={() => history.push('/news/changelog-list')}
                >
                  Cancelar
                </SubmitButton>
              </div>
            </div>
          </Form>
        </Content>
      </Main>

      {logout && <UserLogout message={message} />}
    </Container>
  );
}
