import React from 'react';

// import { Container } from './styles';

export default function Forgot() {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <h1>Em breve!</h1>
    </div>
  );
}
