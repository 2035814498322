import styled from 'styled-components';

export const Container = styled.div`
  padding: 0.5rem 2rem;
  border-radius: 0.4rem;
  border-left: 0.1rem solid var(--color-white);
  background: var(--color-primary);

  &:hover {
    opacity: var(--opacity-semiOpaque);
  }

  div.title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    div {
      display: flex;
      align-items: center;

      svg {
        color: ${props =>
          props.pro ? 'var(--color-tertiary)' : 'var(--color-background)'};
        opacity: var(--opacity-semiOpaque);
      }

      strong {
        margin-left: 0.5rem;
      }
    }

    button {
      border: 0;
      background: transparent;
      color: var(--color-white);
    }
  }

  hr {
    border: 0;
    height: 0.1rem;
    background-color: var(--color-tertiary);

    margin: 1rem 0;
  }

  div.date {
    display: flex;
    justify-content: flex-end;

    span {
      font-size: var(--fontSizes-xl);
      opacity: var(--opacity-medium);
      color: var(--color-white);
    }
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;

  div {
    margin-right: 1rem;

    img {
      width: 2.8rem;
      height: 2.8rem;
      border-radius: 50%;
      border: 0.1rem solid var(--color-white);
      background: var(--color-background);

      object-fit: cover;
    }
  }

  div.group {
    display: block;

    div {
      display: flex;
    }

    strong {
      max-width: 30ch;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      color: var(--color-white);
    }

    span {
      margin: 1rem 0;
      font-size: var(--fontSizes-2xl);
      opacity: var(--opacity-intense);
      color: var(--color-white);
    }
  }

  @media (min-width: 1180px) {
    div.group {
      strong {
        max-width: 50ch;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
`;
