import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import CircularProgress from '@material-ui/core/CircularProgress';

import { Aside, Container, Main } from '~/components/Body';
import Changelog from '~/components/Changelog';
import Title from '~/components/Title';
import UserLogout from '~/components/UserLogout';
import api from '~/services/api';
import { updateChangelogRequest } from '~/store/modules/changelog/actions';
import { ToastError } from '~/utils/toast';

import Menu from '../../Menu';
import { Scroll } from './styles';

export default function ChangelogList() {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [logout, setLogout] = useState(false);
  const [message, setMessage] = useState('');

  const [changelogs, setChangelogs] = useState([]);

  try {
    useEffect(() => {
      async function loadChangelogs() {
        const response = await api.get('/admin/changelogs').catch(async res => {
          try {
            if (res.response.data.error === 'User disabled') {
              setMessage(
                'Autenticação falhou. Entre em contato com seu administrador'
              );

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Token not provided') {
              setMessage('Autenticação falhou, refaça seu login');

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Token invalid') {
              setMessage('Autenticação inválida, refaça seu login');

              setLogout(true);

              return;
            }
          } catch (error) {
            ToastError({ title: 'Erro ao carregar os dados' });

            setLoading(false);
          }
        });

        if (response === undefined) {
          setLoading(false);

          return;
        }

        setChangelogs(response.data);

        setLoading(false);
      }

      loadChangelogs();
    }, []);
  } catch (error) {
    ToastError({ title: 'Erro ao carregar os dados' });
  }

  async function handleEdit(id) {
    dispatch(updateChangelogRequest(id));
  }

  return (
    <Container>
      <Aside>
        <Menu changelog="changelog" />
      </Aside>

      <Main>
        <Title title="Changelog" count={`[ ${changelogs.length} ]`} />

        <Scroll>
          {loading ? (
            <div className="loading">
              <CircularProgress style={{ color: 'var(--color-secondary)' }} />
            </div>
          ) : (
            <ul>
              {changelogs.map(changelog => (
                <Changelog
                  key={changelog.id}
                  changelogs={changelog}
                  onEdit={() => handleEdit(changelog.id)}
                />
              ))}
              {changelogs.length === 0 && (
                <p className="empty">Nenhum changelog encontrado</p>
              )}
            </ul>
          )}
        </Scroll>
      </Main>

      {logout && <UserLogout message={message} />}
    </Container>
  );
}
