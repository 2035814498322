import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import CircularProgress from '@material-ui/core/CircularProgress';

import { Aside, Container, Main } from '~/components/Body';
import Title from '~/components/Title';
import User from '~/components/User';
import UserLogout from '~/components/UserLogout';
import api from '~/services/api';
import { updateUserRequest } from '~/store/modules/user/actions';
import { ToastError } from '~/utils/toast';

import Menu from '../Menu';
import { Scroll } from './styles';

export default function UserDisabled() {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [logout, setLogout] = useState(false);
  const [message, setMessage] = useState('');

  const [users, setUsers] = useState([]);

  try {
    useEffect(() => {
      async function loadUsers() {
        const response = await api
          .get('/admin/users-disableds')
          .catch(async res => {
            try {
              if (res.response.data.error === 'User disabled') {
                setMessage(
                  'Autenticação falhou. Entre em contato com seu administrador'
                );

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'User without permission') {
                setMessage(
                  'Você não possui permissão para este tipo de operação'
                );

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Token not provided') {
                setMessage('Autenticação falhou, refaça seu login');

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Token invalid') {
                setMessage('Autenticação inválida, refaça seu login');

                setLogout(true);

                return;
              }
            } catch (error) {
              ToastError({ title: 'Erro ao carregar os dados' });

              setLoading(false);
            }
          });

        if (response === undefined) {
          setLoading(false);

          return;
        }

        setUsers(response.data);

        setLoading(false);
      }

      loadUsers();
    }, []);
  } catch (error) {
    ToastError({ title: 'Erro ao carregar os dados' });
  }

  async function handleEdit(id) {
    dispatch(updateUserRequest(id));
  }

  return (
    <Container>
      <Aside>
        <Menu userDisabled="userDisabled" />
      </Aside>

      <Main>
        <Title title="Usuários desativados" count={`[ ${users.length} ]`} />

        <Scroll>
          {loading ? (
            <div className="loading">
              <CircularProgress style={{ color: 'var(--color-secondary)' }} />
            </div>
          ) : (
            <ul>
              {users.map(user => (
                <User
                  key={user.id}
                  user={user}
                  onEdit={() => handleEdit(user.id)}
                />
              ))}
              {users.length === 0 && <p>Nenhum usuário encontrado</p>}
            </ul>
          )}
        </Scroll>
      </Main>

      {logout && <UserLogout message={message} />}
    </Container>
  );
}
