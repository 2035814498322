import produce from 'immer';

export default function changelog(state = {}, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@changelog/UPDATE_CHANGELOG_REQUEST': {
        draft.changelogId = action.payload.id;
        break;
      }
      default:
    }
  });
}
