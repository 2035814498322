import React from 'react';

import { Button } from './styles';

export default function SubmitButton({ type, onClick, children }) {
  return (
    <Button type={type} onClick={onClick}>
      {children}
    </Button>
  );
}
