import React from 'react';
import { MdDelete } from 'react-icons/md';
import { useSelector } from 'react-redux';

import { formatDistance, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';

import { Container, Content, Header } from './styles';

export default function Helpdesk({ helpdesk, onDelete }) {
  const { email } = useSelector(state => state.user.profile);

  const formattedDate = formatDistance(
    parseISO(helpdesk.created_at),
    new Date(),
    {
      addSuffix: true,
      locale: pt,
    }
  );

  return (
    <Container>
      <div className="title">
        <strong>{helpdesk.name}</strong>

        {email === 'admin@praises.com.br' && (
          <button type="button" onClick={onDelete}>
            <MdDelete size={18} />
          </button>
        )}
      </div>

      <hr />

      <Content>
        <Header>
          <div className="group">
            <div>
              <strong>{helpdesk.email}</strong>
            </div>

            <div>
              <strong>{helpdesk.whatsapp}</strong>
            </div>
          </div>
        </Header>

        <div>
          <span>{helpdesk.description}</span>
        </div>
      </Content>

      <div className="date">
        <span>{formattedDate}</span>
      </div>
    </Container>
  );
}
