import React from 'react';
import { MdModeEdit } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import photoDefault from '~/assets/photo-default.svg';

import { Container, Content, Header } from './styles';

export default function UserActive({ user, onEdit }) {
  const { id, permission } = useSelector(state => state.user.profile);

  return (
    <Container>
      <Content permission={!permission}>
        <Header>
          <img src={user.photo ? user.url : photoDefault} alt="" />

          <div>
            <strong>{user.name}</strong>
            <span>{user.email}</span>
          </div>
        </Header>

        {permission ? (
          <div>
            {id !== user.id && user.email !== 'admin@praises.com.br' ? (
              <Link
                className="permission"
                title="Alterar cadastro"
                to="/user/user-update"
                onClick={onEdit}
              >
                <MdModeEdit size={20} />
              </Link>
            ) : (
              ''
            )}
          </div>
        ) : (
          ''
        )}
      </Content>
    </Container>
  );
}
