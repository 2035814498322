import React from 'react';
import { MdCloud, MdDelete } from 'react-icons/md';
import { useSelector } from 'react-redux';

import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';

import photoDefault from '~/assets/photo-default.svg';

import { Container, Content, Header } from './styles';

export default function History({ history, onDelete }) {
  const { email } = useSelector(state => state.user.profile);

  const formattedDate = format(
    parseISO(history.created_at),
    "'Dia' dd 'às' HH:mm",
    {
      locale: pt,
    }
  );

  return (
    <Container pro={history.tenant.pro}>
      <div className="title">
        <div>
          <MdCloud size={18} />

          <strong>{history.tenant.nickname}</strong>
        </div>

        {email === 'admin@praises.com.br' && (
          <button type="button" onClick={onDelete}>
            <MdDelete size={18} />
          </button>
        )}
      </div>

      <hr />

      <Content>
        <Header>
          <div>
            <img
              src={history.user.photo ? history.user.url : photoDefault}
              alt=""
            />
          </div>

          <div className="group">
            <strong>{history.user.nameuser}</strong>

            <div>
              <span className="type">{history.type} - </span>

              <span>{history.register}</span>
            </div>
          </div>
        </Header>

        <div>
          <span>{formattedDate}</span>
        </div>
      </Content>
    </Container>
  );
}
