import React, { useEffect, useState } from 'react';
import Switch from 'react-switch';

import CircularProgress from '@material-ui/core/CircularProgress';
import { Form } from '@rocketseat/unform';
import axios from 'axios';
import { addMonths, format } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import * as Yup from 'yup';

import { Aside, Container, Main } from '~/components/Body';
import InputText from '~/components/InputText';
import SubmitButton from '~/components/SubmitButton';
import Title from '~/components/Title';
import UserLogout from '~/components/UserLogout';
import api from '~/services/api';
import { cep, cnpjMask, cpfMask, phoneMask } from '~/utils/inputMask';
import { ToastError, ToastSuccess } from '~/utils/toast';

import Menu from '../Menu';
import { Content, Label } from './styles';

const schema = Yup.object().shape({
  name: Yup.string()
    .min(10, 'Nome deve ter no mínimo 10 caracteres')
    .max(100, 'Nome deve ter no máximo 100 caracteres'),
  nickname: Yup.string()
    .min(3, 'Nome deve ter no mínimo 3 caracteres')
    .max(20, 'Nome deve ter no máximo 20 caracteres'),
  document: Yup.string().length(18, 'CNPJ dever ser preenchido apenas números'),
  people: Yup.number()
    .min(10, 'Limite de pessoas deve ter no mínimo 10')
    .typeError('Limite de pessoas é obrigatório'),
  price: Yup.number()
    .min(0, 'Valor mínimo por pessoa R$ 0,00')
    .typeError('Valor por pessoa é obrigatório'),
  responsible: Yup.string()
    .min(10, 'Responsável deve ter no mínimo 10 caracteres')
    .max(50, 'Responsável deve ter no máximo 50 caracteres'),
  active: Yup.boolean(),
  zip_code: Yup.string(),
  number: Yup.string(),
  complement: Yup.string(),
  pro: Yup.boolean(),
  nameuser: Yup.string()
    .min(3, 'Nome de usuário deve ter no mínimo 3 caracteres')
    .max(20, 'Nome de usuário deve ter no máximo 20 caracteres'),
  email: Yup.string()
    .email('Insira um e-mail válido')
    .min(7, 'E-mail deve ter no mínimo 7 caracteres')
    .max(50, 'E-mail deve ter no máximo 50 caracteres'),
  password: Yup.string()
    .min(4, 'Senha deve ter no mínimo 4 caracteres')
    .max(20, 'Senha deve ter no máximo 20 caracteres'),
  confirmPassword: Yup.string()
    .min(4, 'Confirme a senha deve ter no mínimo 4 caracteres')
    .max(20, 'Confirme a senha deve ter no máximo 20 caracteres')
    .oneOf([Yup.ref('password'), null], 'Senhas não conferem'),
  responsible_document: Yup.string(),
  phone: Yup.string().length(16, 'Celular deve ser preenchido apenas números'),
});

export default function TenantNew() {
  const [loading, setLoading] = useState(false);
  const [logout, setLogout] = useState(false);
  const [message, setMessage] = useState('');

  const available = format(addMonths(new Date(), 1), 'yyyy-MM-dd');

  const [name, setName] = useState('');
  const [nickname, setNickname] = useState('');
  const [document, setDocument] = useState('');
  const [people, setPeople] = useState('');
  const [price, setPrice] = useState('');
  const [responsible, setResponsible] = useState('');
  const [active, setActive] = useState(true);
  const [zip_code, setZip_code] = useState('');
  const [street, setStreet] = useState('');
  const [number, setNumber] = useState('');
  const [complement, setComplement] = useState('');
  const [district, setDistrict] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');

  const [pro, setPro] = useState(false);

  const [nameuser, setNameuser] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [responsibleDocument, setResponsibleDocument] = useState('');
  const [phone, setPhone] = useState('');

  useEffect(() => {
    async function cepUser() {
      if (zip_code.length === 9) {
        const response = await axios.get(
          `https://viacep.com.br/ws/${zip_code.replace(/\D/g, '')}/json/`
        );

        const { logradouro, bairro, localidade, uf } = response.data;

        setStreet(logradouro);
        setDistrict(bairro);
        setCity(localidade);
        setState(uf);
      }
    }

    cepUser();
  }, [zip_code]);

  async function handleSubmit() {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const timezoneDate = zonedTimeToUtc(available, timezone);
    const birthday = zonedTimeToUtc(format(new Date(), 'yyyy-MM-dd'), timezone);

    const nicknameTrim = nickname.trim();

    try {
      setLoading(true);

      const data = await api
        .post('/admin/tenants', {
          name,
          nickname: nicknameTrim,
          document,
          people,
          price,
          available: timezoneDate,
          responsible,
          active,
          zip_code,
          number,
          complement,
          pro,
          nameuser,
          email,
          password,
          responsible_document: responsibleDocument,
          phone,
          birthday,
        })
        .catch(async res => {
          try {
            if (res.response.data.error === 'Validation fails') {
              ToastError({
                title:
                  'Seus dados foram recusados. Verifique-os e tente novamente',
              });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'User disabled') {
              setMessage(
                'Autenticação falhou. Entre em contato com seu administrador'
              );

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'CNPJ is invalid') {
              ToastError({ title: 'CNPJ é inválido' });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'Nickname already exists') {
              ToastError({ title: 'Conta já existe' });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'User name already exists') {
              ToastError({ title: 'Nome de usuário já existe' });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'Email already exists') {
              ToastError({ title: 'E-mail já existe' });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'CPF is invalid') {
              ToastError({ title: 'CPF é inválido' });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'Id already exists') {
              ToastError({ title: 'Recarregue a pagina e tente novamente' });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'IdUser already exists') {
              ToastError({ title: 'Recarregue a pagina e tente novamente' });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'Token not provided') {
              setMessage('Autenticação falhou, refaça seu login');

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Token invalid') {
              setMessage('Autenticação inválida, refaça seu login');

              setLogout(true);

              return;
            }
          } catch (error) {
            ToastError({ title: 'Erro ao adicionar, verifique os dados' });

            setLoading(false);
          }
        });

      if (data === undefined) {
        setLoading(false);

        return;
      }

      ToastSuccess({ title: 'Conta adicionada' });

      setName('');
      setNickname('');
      setDocument('');
      setPeople('');
      setPrice('');
      setZip_code('');
      setStreet('');
      setNumber('');
      setComplement('');
      setDistrict('');
      setCity('');
      setState('');

      setPro(false);

      setResponsible('');
      setNameuser('');
      setEmail('');
      setPassword('');
      setConfirmPassword('');
      setResponsibleDocument('');
      setPhone('');

      setLoading(false);
    } catch (error) {
      setLoading(false);

      ToastError({ title: 'Erro ao adicionar, verifique os dados' });
    }
  }

  return (
    <Container>
      <Aside>
        <Menu tenantNew="tenantNew" />
      </Aside>

      <Main>
        <Title title="Adicionar conta" />

        <Content>
          <Form schema={schema} onSubmit={handleSubmit}>
            <div className="group">
              <div className="name">
                <InputText
                  label="Nome"
                  name="name"
                  value={name}
                  onChange={event => setName(event.target.value)}
                />
              </div>

              <div className="document">
                <InputText
                  label="CNPJ"
                  name="document"
                  value={document}
                  onChange={event => setDocument(cnpjMask(event.target.value))}
                />
              </div>
            </div>

            <div className="group">
              <div className="people">
                <InputText
                  label="Limite de pessoas"
                  name="people"
                  type="number"
                  value={people}
                  onChange={event => setPeople(event.target.value)}
                />
              </div>

              <div className="price">
                <InputText
                  label="Valor por pessoa"
                  name="price"
                  type="number"
                  step=".01"
                  value={price}
                  onChange={event => setPrice(event.target.value)}
                />
              </div>

              <div className="nickname">
                <InputText
                  label="Conta"
                  name="nickname"
                  value={nickname}
                  onChange={event => setNickname(event.target.value)}
                />
              </div>
            </div>

            <div className="group">
              <div className="zip_code">
                <InputText
                  label="CEP"
                  name="zip_code"
                  value={zip_code}
                  onChange={event => setZip_code(cep(event.target.value))}
                />
              </div>

              <div className="street">
                <InputText
                  label="Rua"
                  title="Rua preenchida automaticamente de acordo com o CEP"
                  name="street"
                  className="disabled"
                  value={street}
                  disabled
                />
              </div>
            </div>

            <div className="group">
              <div className="number">
                <InputText
                  label="Número"
                  name="number"
                  type="number"
                  value={number}
                  onChange={event => setNumber(event.target.value)}
                />
              </div>

              <div className="complement">
                <InputText
                  label="Complemento"
                  name="complement"
                  value={complement}
                  onChange={event => setComplement(event.target.value)}
                />
              </div>
            </div>

            <div className="group">
              <div className="district">
                <InputText
                  label="Bairro"
                  title="Bairro preenchido automaticamente de acordo com o CEP"
                  name="district"
                  className="disabled"
                  value={district}
                  disabled
                />
              </div>

              <div className="city">
                <InputText
                  label="Cidade"
                  title="Cidade preenchida automaticamente de acordo com o CEP"
                  name="city"
                  className="disabled"
                  value={city}
                  disabled
                />
              </div>

              <div className="state">
                <InputText
                  label="UF"
                  title="UF preenchido automaticamente de acordo com o CEP"
                  name="state"
                  className="disabled"
                  value={state}
                  disabled
                />
              </div>
            </div>

            <h2>Dados do responsável</h2>

            <div className="group">
              <div className="responsible">
                <InputText
                  label="Nome completo"
                  name="responsible"
                  value={responsible}
                  onChange={event => setResponsible(event.target.value)}
                />
              </div>

              <div className="nameuser">
                <InputText
                  label="Nome de usuário"
                  name="nameuser"
                  value={nameuser}
                  onChange={event =>
                    setNameuser(
                      event.target.value.toLowerCase().replace(/\s+/g, '')
                    )
                  }
                />
              </div>
            </div>

            <div className="group">
              <div className="email">
                <InputText
                  label="E-mail"
                  name="email"
                  type="email"
                  value={email}
                  onChange={event => setEmail(event.target.value)}
                />
              </div>

              <div className="password">
                <InputText
                  label="Senha"
                  name="password"
                  type="password"
                  autoComplete="cc-csc"
                  value={password}
                  onChange={event => setPassword(event.target.value)}
                />
              </div>

              <div className="confirm-password">
                <InputText
                  label="Confirme a senha"
                  name="confirmPassword"
                  type="password"
                  autoComplete="cc-csc"
                  value={confirmPassword}
                  onChange={event => setConfirmPassword(event.target.value)}
                />
              </div>
            </div>

            <div className="group">
              <div className="responsible_document">
                <InputText
                  label="CPF"
                  name="responsible_document"
                  value={responsibleDocument}
                  onChange={event =>
                    setResponsibleDocument(cpfMask(event.target.value))
                  }
                />
              </div>

              <div className="phone">
                <InputText
                  label="Celular"
                  name="phone"
                  value={phone}
                  onChange={event => setPhone(phoneMask(event.target.value))}
                />
              </div>
            </div>

            <div className="group-switch">
              <div>
                <Label>Ativar</Label>
                <div className="permission">
                  <Switch
                    height={15}
                    width={40}
                    handleDiameter={20}
                    onColor="#247BA0"
                    offColor="#13293D"
                    onChange={() => setActive(!active)}
                    checked={active}
                  />
                </div>
              </div>
            </div>

            <hr />

            <div className="group-switch">
              <div>
                <Label>Pro</Label>
                <div className="permission">
                  <Switch
                    height={15}
                    width={40}
                    handleDiameter={20}
                    onColor="#247BA0"
                    offColor="#13293D"
                    onChange={() => setPro(!pro)}
                    checked={pro}
                  />
                </div>
              </div>
            </div>

            <hr />

            <div className="group">
              <div>
                <SubmitButton type="submit" disabled={loading}>
                  {loading ? (
                    <CircularProgress
                      size={24}
                      style={{ color: 'var(--color-white)' }}
                    />
                  ) : (
                    'Salvar'
                  )}
                </SubmitButton>
              </div>

              <div className="cancel">
                <SubmitButton
                  type="button"
                  onClick={() => window.history.back()}
                >
                  Cancelar
                </SubmitButton>
              </div>
            </div>
          </Form>
        </Content>
      </Main>

      {logout && <UserLogout message={message} />}
    </Container>
  );
}
