import styled from 'styled-components';

export const Content = styled.div`
  padding: 2rem;
  border-radius: 0.4rem;
  background: var(--color-primary);

  form {
    div.group {
      display: block;
      padding-bottom: 1rem;

      div.name,
      div.people,
      div.price,
      div.available,
      div.created,
      div.responsible,
      div.email,
      div.zip_code,
      div.number,
      div.district,
      div.city {
        margin-bottom: 1rem;
      }

      div {
        width: 100%;

        input.disabled {
          color: var(--color-yellow);

          cursor: not-allowed;
        }
      }

      div.cancel {
        margin-left: 0;

        button {
          background: var(--color-red);
          &:hover {
            opacity: var(--opacity-semiOpaque);
          }
        }
      }
    }

    hr {
      border: 0;
      height: 0.1rem;
      background-color: var(--color-tertiary);

      margin: 0.5rem 0;
    }

    div.group-switch {
      display: flex;
      align-items: center;

      div {
        width: 100%;
      }

      div.permission {
        padding: 0.5rem 0 0 0;
      }
    }

    button.delete {
      margin-top: 0;

      border: none;
      background: none;
      box-shadow: rgb(232 241 242 / 10%) 0 0.5rem 2rem;
      color: var(--color-red);
      font-weight: bold;

      &:hover {
        opacity: var(--opacity-semiOpaque);
      }
    }

    hr.hr-new-user {
      margin: 1.5rem 0;
    }

    div.div-input-new-user {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 2rem;

      strong {
        font-size: var(--fontSizes-5xl);
      }

      button {
        border: 0;
        background: transparent;
        color: var(--color-white);
      }
    }
  }

  div.div-new-user {
    display: flex;

    div {
      width: 100%;
    }

    button {
      margin: 0 1rem;

      border: 0;
      background: transparent;

      color: var(--color-white);
    }
  }

  @media (min-width: 1180px) {
    form {
      div.group {
        display: flex;
        padding-bottom: 0;

        div.name,
        div.people,
        div.price,
        div.available,
        div.created,
        div.responsible,
        div.email,
        div.zip_code,
        div.number,
        div.district,
        div.city {
          margin-right: 1rem;
        }

        div.document,
        div.nickname,
        div.phone,
        div.document_responsible {
          width: 40%;
        }

        div.people,
        div.price,
        div.zip_code,
        div.number,
        div.state {
          width: 30%;
        }

        div.cancel {
          display: flex;
          justify-content: flex-end;

          button {
            width: 50%;
          }
        }
      }

      hr.hr-new-user {
        margin: 2.5rem 0;
      }
    }

    div.div-new-user {
      margin-bottom: 2rem;
    }
  }
`;

export const User = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 4.4rem;
  padding: 0 1.5rem;
  border-radius: 0.4rem;
  border: 0.1rem solid var(--color-tertiary);
  background: var(--color-primary);
  color: var(--color-white);

  margin: 1rem 0;

  button {
    border: 0;
    background: transparent;

    color: var(--color-white);
  }

  @media (min-width: 1180px) {
    margin-bottom: 0;
  }
`;

export const Label = styled.label``;
