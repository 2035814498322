import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;

  span {
    margin-bottom: 8;
    opacity: var(--opacity-intense);
  }
`;

export const DivInfoCount = styled.aside`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;

  ::-webkit-scrollbar {
    display: none;
  }

  margin-bottom: 2rem;

  div + div {
    margin-left: 1rem;
  }
`;

export const Scroll = styled.div`
  div.loading {
    display: flex;
    justify-content: center;
  }

  ul {
    display: grid;
    gap: 0.5rem;

    p {
      display: flex;
      justify-content: center;
      font-size: var(--fontSizes-2xl);
      color: var(--color-tertiary);
    }
  }
`;
