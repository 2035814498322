import styled from 'styled-components';

export const Button = styled.button`
  width: 50%;
  height: 4.4rem;
  border-radius: 0.4rem;
  border: 0;
  background: var(--color-primary);
  padding: 0.4rem;

  font-size: var(--fontSizes-2xl);
  font-weight: bold;
  color: var(--color-white);

  margin-right: 1rem;

  @media (min-width: 1180px) {
    &:hover {
      color: var(--color-background);
    }
  }
`;
