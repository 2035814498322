import React from 'react';
import { MdArrowBack } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import logo from '~/assets/logo.png';
import photoDefault from '~/assets/photo-default.svg';
import Notifications from '~/components/Notifications';

import { Container, Content, Profile } from './styles';

export default function Header() {
  const profile = useSelector(state => state.user.profile);

  return (
    <Container>
      <Content>
        <nav>
          <Link to="/">
            <img src={logo} alt="Praise" />
            <strong>PRAISE</strong>
          </Link>

          <button type="button" onClick={() => window.history.back()}>
            <MdArrowBack />
            <strong className="home">Voltar</strong>
          </button>
        </nav>

        <aside>
          <Notifications />

          <Profile>
            <Link to="/account/profile" title="Alterar conta">
              <div className="title">
                <strong>{profile.name}</strong>
              </div>

              <div className="photo">
                <img src={profile.photo ? profile.url : photoDefault} alt="" />
              </div>
            </Link>
          </Profile>
        </aside>
      </Content>
    </Container>
  );
}
