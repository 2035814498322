import React from 'react';
import { MdEdit } from 'react-icons/md';
import ReactPlayer from 'react-player';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { formatDistance, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';

import Markdown from '~/components/Markdown';

import { Container } from './styles';

export default function Changelog({ changelogs, onEdit }) {
  const { permission } = useSelector(state => state.user.profile);

  const formattedDate = formatDistance(
    parseISO(changelogs.updated_at),
    new Date(),
    {
      addSuffix: true,
      locale: pt,
    }
  );

  return (
    <Container>
      <div className="version">
        <strong>{changelogs.version}</strong>

        {permission && (
          <div>
            <Link to="/news/changelog-update" title="Alterar" onClick={onEdit}>
              <MdEdit size={20} />
            </Link>
          </div>
        )}
      </div>

      {changelogs.link && (
        <div className="div-player">
          <ReactPlayer url={changelogs.link} playing controls light />
        </div>
      )}

      <div>
        <pre>
          <Markdown data={changelogs.change} />
        </pre>
      </div>

      <div>
        <span className="launch">{formattedDate}</span>
      </div>
    </Container>
  );
}
