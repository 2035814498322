import styled from 'styled-components';

export const Div = styled.div`
  input {
    width: 100%;
    height: 4.4rem;
    padding: 0 1.5rem;
    border-radius: 0.4rem;
    border: 0.1rem solid var(--color-secondary);
    background: var(--color-background);
    color: var(--color-white);

    margin: 1rem 0;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &:focus {
      border: 0.1rem solid var(--color-white);
    }
  }

  textarea {
    width: 100%;
    height: 14rem;
    padding: 1.5rem;
    border-radius: 0.4rem;
    border: 0.1rem solid var(--color-secondary);
    background: var(--color-background);
    color: var(--color-white);

    resize: none;
    font-size: var(--fontSizes-2xl);
    line-height: 3rem;

    margin: 1rem 0 1rem;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &:focus {
      border: 0.1rem solid var(--color-white);
    }
  }

  span {
    margin: 0 0 1rem;
    align-self: flex-start;
    color: var(--color-mandatory);
  }
`;
