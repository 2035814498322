import { combineReducers } from 'redux';

import auth from './auth/reducer';
import changelog from './changelog/reducer';
import tenant from './tenant/reducer';
import user from './user/reducer';

export default combineReducers({
  auth,
  user,
  tenant,
  changelog,
});
