import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  a,
  button {
    svg {
      margin-bottom: 0.5rem;
    }
  }

  button {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    font-size: var(--fontSizes-md);
    color: var(--color-red);
    background: var(--color-primary);

    width: 8rem;
    height: 6rem;
    border-radius: 0.5rem;
    border: 0;
    border-top: 0.1rem solid var(--color-red);

    margin-bottom: 1rem;
    margin-left: 0.5rem;

    -webkit-box-align: center;
    -webkit-box-pack: justify;
  }

  @media (min-width: 1180px) {
    display: flex;
    flex-direction: column;
    margin-right: 4rem;

    a,
    button {
      svg {
        margin-bottom: 0;
      }
    }

    button {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: space-between;

      height: 6rem;
      padding: 2rem;

      border-top: none;
      border-left: 0.1rem solid var(--color-red);

      width: 23rem;
      font-size: var(--fontSizes-2xl);
      font-weight: bold;

      margin-right: 0.5rem;
      &:hover {
        border-top: none;
        border-left: 0.3rem solid var(--color-red);
      }
    }
  }
`;
