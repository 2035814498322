import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Switch from 'react-switch';

import CircularProgress from '@material-ui/core/CircularProgress';
import { Form } from '@rocketseat/unform';
import { format, parseISO } from 'date-fns';
import Swal from 'sweetalert2/src/sweetalert2';
import * as Yup from 'yup';

import { Aside, Container, Main } from '~/components/Body';
import InputText from '~/components/InputText';
import SubmitButton from '~/components/SubmitButton';
import Title from '~/components/Title';
import UserLogout from '~/components/UserLogout';
import api from '~/services/api';
import { cpfMask, phoneMask } from '~/utils/inputMask';
import { ToastError, ToastSuccess } from '~/utils/toast';

import Menu from '../Menu';
import { Content, Label } from './styles';

const schema = Yup.object().shape({
  name: Yup.string()
    .min(10, 'Nome completo deve ter no mínimo 10 caracteres')
    .max(50, 'Nome completo deve ter no máximo 50 caracteres'),
  nameuser: Yup.string()
    .min(3, 'Nome de usuário deve ter no mínimo 3 caracteres')
    .max(20, 'Nome de usuário deve ter no máximo 20 caracteres'),
  email: Yup.string()
    .email('Insira um e-mail válido')
    .min(7, 'E-mail deve ter no mínimo 7 caracteres')
    .max(50, 'E-mail deve ter no máximo 50 caracteres'),
  password: Yup.string(),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref('password'), null],
    'Senhas não conferem'
  ),
  document: Yup.string().length(14, 'CPF deve ser preenchido apenas números'),
  phone: Yup.string().length(16, 'Celular deve ser preenchido apenas números'),
  active: Yup.boolean(),
  permission: Yup.boolean(),
});

export default function UserUpdate() {
  const { email: masterEmail } = useSelector(state => state.user.profile);

  const id = useSelector(state => state.user.userId);

  const [loading, setLoading] = useState(false);
  const [logout, setLogout] = useState(false);
  const [message, setMessage] = useState('');

  const [users, setUsers] = useState([]);
  const [nameusers, setNameusers] = useState([]);
  const [userPhone, setUserPhone] = useState([]);
  const [userDocument, setUserDocument] = useState([]);
  const [active, setActive] = useState(false);
  const [permission, setPermission] = useState(false);

  try {
    useEffect(() => {
      async function loadUserData() {
        const { data } = await api.get(`/admin/users/${id}`);

        const {
          name,
          nameuser,
          email,
          document,
          phone,
          created_at,
          updated_at,
        } = data;

        const parsedCreate = parseISO(created_at);
        const parsedUpdate = parseISO(updated_at);

        const formattedCreate = format(parsedCreate, 'yyyy-MM-dd');
        const formattedUpdate = format(parsedUpdate, 'yyyy-MM-dd');

        const response = {
          name,
          nameuser,
          email,
          document,
          phone,
          created_at: formattedCreate,
          updated_at: formattedUpdate,
        };

        setUsers(response);
        setNameusers(response.nameuser);
        setUserDocument(response.document);
        setUserPhone(response.phone);
        setActive(data.active);
        setPermission(data.permission);
      }

      loadUserData();
    }, [id]);
  } catch (error) {
    ToastError({ title: 'Erro ao carregar os dados' });
  }

  async function handleSubmit({
    name,
    nameuser,
    email,
    document,
    phone,
    password,
  }) {
    try {
      setLoading(true);

      const data = Object.assign(
        { name, nameuser, email, document, phone, active, permission },
        password ? { password } : {}
      );

      const dataResponse = await api
        .put(`/admin/users/${id}`, data)
        .catch(async res => {
          try {
            if (res.response.data.error === 'Validation fails') {
              ToastError({
                title:
                  'Seus dados foram recusados. Verifique-os e tente novamente',
              });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'User without permission') {
              setMessage(
                'Você não possui permissão para este tipo de operação'
              );

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'This user cannot be changed') {
              ToastError({ title: 'Esse usuário não pode ser alterado' });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'User name already exists') {
              ToastError({ title: 'Nome de usuário já existe' });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'Email already exists') {
              ToastError({ title: 'E-mail já existe' });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'Document already exists') {
              ToastError({ title: 'CPF já existe' });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'CPF is invalid') {
              ToastError({ title: 'CPF é inválido' });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'Token not provided') {
              setMessage('Autenticação falhou, refaça seu login');

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Token invalid') {
              setMessage('Autenticação inválida, refaça seu login');

              setLogout(true);

              return;
            }
          } catch (error) {
            ToastError({ title: 'Erro ao alterar, verifique os dados' });

            setLoading(false);
          }
        });

      if (dataResponse === undefined) return;

      ToastSuccess({ title: 'Usuário alterado' });

      window.history.back();
    } catch (error) {
      setLoading(false);

      ToastError({ title: 'Erro ao alterar, verifique os dados' });
    }
  }

  async function handleDelete() {
    Swal.fire({
      title: 'Você tem certeza?',
      text: 'Você não poderá reverter isso!',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: 'var(--color-blue)',
      cancelButtonColor: 'var(--color-red)',
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar',
    }).then(async result => {
      if (result.value) {
        try {
          const dataResponse = await api
            .delete(`/admin/users/${id}`)
            .catch(async res => {
              try {
                if (res.response.data.error === 'User without permission') {
                  setMessage(
                    'Você não possui permissão para este tipo de operação'
                  );

                  setLogout(true);

                  return;
                }

                if (res.response.data.error === 'Token not provided') {
                  setMessage('Autenticação falhou, refaça seu login');

                  setLogout(true);

                  return;
                }

                if (res.response.data.error === 'Token invalid') {
                  setMessage('Autenticação inválida, refaça seu login');

                  setLogout(true);

                  return;
                }
              } catch (error) {
                ToastError({ title: 'Erro ao excluir' });

                setLoading(false);
              }
            });

          if (dataResponse === undefined) return;

          Swal.fire('Excluído!', 'Usuário excluído', 'success');

          window.history.back();
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Algo deu errado!',
          });
        }
      }
    });
  }

  return (
    <Container>
      <Aside>
        <Menu />
      </Aside>

      <Main>
        <Title title="Alterar cadastro" />

        <Content>
          <Form schema={schema} initialData={users} onSubmit={handleSubmit}>
            <div className="group">
              <div className="name">
                <InputText label="Nome completo" name="name" />
              </div>

              <div className="nameuser">
                <InputText
                  label="Nome de usuário"
                  name="nameuser"
                  value={nameusers}
                  onChange={event =>
                    setNameusers(
                      event.target.value.toLowerCase().replace(/\s+/g, '')
                    )
                  }
                />
              </div>
            </div>

            <div className="group">
              <div className="email">
                <InputText label="E-mail" name="email" type="email" />
              </div>

              <div className="password">
                <InputText
                  label="Senha"
                  placeholder="****"
                  name="password"
                  type="password"
                  autoComplete="cc-csc"
                />
              </div>

              <div className="confirm-password">
                <InputText
                  label="Confirme a senha"
                  placeholder="****"
                  name="confirmPassword"
                  type="password"
                  autoComplete="cc-csc"
                />
              </div>
            </div>

            <div className="group">
              <div className="document">
                <InputText
                  label="CPF"
                  name="document"
                  value={userDocument}
                  onChange={event =>
                    setUserDocument(cpfMask(event.target.value))
                  }
                />
              </div>

              <div className="phone">
                <InputText
                  label="Celular"
                  name="phone"
                  value={userPhone}
                  onChange={event =>
                    setUserPhone(phoneMask(event.target.value))
                  }
                />
              </div>
            </div>

            <div className="group">
              <div className="created">
                <InputText
                  label="Cadastrado"
                  title="Data que foi cadastro"
                  className="disabled"
                  name="created_at"
                  type="date"
                  disabled
                />
              </div>

              <div className="updated">
                <InputText
                  label="Alterado"
                  title="Data que foi alterado"
                  className="disabled"
                  name="updated_at"
                  type="date"
                  disabled
                />
              </div>
            </div>

            <div className="group-switch">
              <div>
                <Label>Administrador</Label>
                <div className="permission">
                  <Switch
                    height={15}
                    width={40}
                    handleDiameter={20}
                    onColor="#247BA0"
                    offColor="#13293D"
                    onChange={() => setPermission(!permission)}
                    checked={permission}
                  />
                </div>
              </div>

              <div>
                <Label>Ativo</Label>
                <div className="permission">
                  <Switch
                    height={15}
                    width={40}
                    handleDiameter={20}
                    onColor="#247BA0"
                    offColor="#13293D"
                    onChange={() => setActive(!active)}
                    checked={active}
                  />
                </div>
              </div>
            </div>

            <hr />

            {masterEmail === 'admin@praises.com.br' && (
              <button
                type="button"
                className="delete"
                onClick={() => handleDelete()}
              >
                Excluir usuário
              </button>
            )}

            <div className="group">
              <div>
                <SubmitButton type="submit" disabled={loading}>
                  {loading ? (
                    <CircularProgress
                      size={24}
                      style={{ color: 'var(--color-white)' }}
                    />
                  ) : (
                    'Salvar'
                  )}
                </SubmitButton>
              </div>

              <div className="cancel">
                <SubmitButton
                  type="button"
                  onClick={() => window.history.back()}
                >
                  Cancelar
                </SubmitButton>
              </div>
            </div>
          </Form>
        </Content>
      </Main>

      {logout && <UserLogout message={message} />}
    </Container>
  );
}
