import styled from 'styled-components';

export const Content = styled.div`
  div {
    display: flex;
    align-items: center;
    justify-content: center;

    a svg {
      margin-bottom: 0.5rem;
    }
  }

  @media (min-width: 1180px) {
    div {
      display: flex;
      flex-direction: column;
      margin-right: 2rem;

      a svg {
        margin-bottom: 0;
      }
    }
  }
`;
