import React from 'react';
import { MdHome, MdLock, MdPerson, MdPowerSettingsNew } from 'react-icons/md';
import { useDispatch } from 'react-redux';

import MenuButton from '~/components/MenuButton';
import { signOut } from '~/store/modules/auth/actions';

import { Content } from './styles';

export default function Menu({ profile, password }) {
  const dispatch = useDispatch();

  function handleSignOut() {
    dispatch(signOut());
  }

  return (
    <Content>
      <MenuButton to="/dashboard">
        <MdHome size={18} /> Home
      </MenuButton>

      <MenuButton to="/account/profile" focus={profile}>
        <MdPerson size={18} /> Meu perfil
      </MenuButton>

      <MenuButton to="/account/password" focus={password}>
        <MdLock size={18} /> Senha secreta
      </MenuButton>

      <button type="button" onClick={handleSignOut}>
        <MdPowerSettingsNew size={18} /> Sair do Praise
      </button>
    </Content>
  );
}
