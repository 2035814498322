import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import CircularProgress from '@material-ui/core/CircularProgress';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import Swal from 'sweetalert2/src/sweetalert2';

import { Aside, Container, Main } from '~/components/Body';
import History from '~/components/History';
import InputText from '~/components/InputText';
import SubMenuButton from '~/components/SubMenuButton';
import Title from '~/components/Title';
import UserLogout from '~/components/UserLogout';
import api from '~/services/api';
import { ToastError } from '~/utils/toast';

import Menu from '../../Menu';
import { Scroll } from './styles';

export default function Historys() {
  const historys = useHistory();

  const [loading, setLoading] = useState(true);
  const [logout, setLogout] = useState(false);
  const [message, setMessage] = useState('');

  const [histories, setHistories] = useState([]);

  const currentDate = format(new Date(), 'yyyy-MM');

  const [month, setMonth] = useState(currentDate);

  const [search, setSearch] = useState('');

  const filteredHistorys = search
    ? histories.filter(history =>
        history.tenant.nickname.toLowerCase().includes(search.toLowerCase())
      )
    : histories;

  try {
    useEffect(() => {
      async function loadHistories() {
        if (month.length !== currentDate.length) {
          return;
        }

        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const monthResult = utcToZonedTime(month, timezone);

        const response = await api
          .get('/admin/historys-church', {
            params: {
              month: monthResult,
            },
          })
          .catch(async res => {
            try {
              if (res.response.data.error === 'Token not provided') {
                setMessage('Autenticação falhou, refaça seu login');

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Token invalid') {
                setMessage('Autenticação inválida, refaça seu login');

                setLogout(true);

                return;
              }
            } catch (error) {
              ToastError({ title: 'Erro ao carregar os dados' });

              setLoading(false);
            }
          });

        if (response === undefined) {
          setLoading(false);

          return;
        }

        setHistories(response.data);

        setLoading(false);
      }

      loadHistories();
    }, [currentDate.length, month]);
  } catch (error) {
    ToastError({ title: 'Erro ao carregar os dados' });
  }

  async function handleDelete(id) {
    Swal.fire({
      title: 'Você tem certeza?',
      text: 'Você não poderá reverter isso!',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: 'var(--color-blue)',
      cancelButtonColor: 'var(--color-red)',
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar',
    }).then(async result => {
      if (result.value) {
        try {
          const dataResponse = await api
            .delete(`/admin/historys-church/${id}`)
            .catch(async res => {
              try {
                if (res.response.data.error === 'User without permission') {
                  setMessage(
                    'Você não possui permissão para este tipo de operação'
                  );

                  setLogout(true);

                  return;
                }

                if (res.response.data.error === 'Token not provided') {
                  setMessage('Autenticação falhou, refaça seu login');

                  setLogout(true);

                  return;
                }

                if (res.response.data.error === 'Token invalid') {
                  setMessage('Autenticação inválida, refaça seu login');

                  setLogout(true);

                  return;
                }
              } catch (error) {
                ToastError({ title: 'Erro ao excluir' });

                setLoading(false);
              }
            });

          if (dataResponse === undefined) return;

          Swal.fire('Excluído!', 'Histórico excluído', 'success');

          setHistories(histories.filter(history => history.id !== id));
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Algo deu errado!',
          });
        }
      }
    });
  }

  return (
    <Container>
      <Aside>
        <Menu tenantInfo="tenantInfo" />
      </Aside>

      <Main>
        <Title
          title="Histórico de alterações"
          count={`[ ${filteredHistorys.length} ]`}
        />

        <Scroll>
          <div className="buttons">
            <SubMenuButton
              type="button"
              onClick={() => historys.push('/tenant/tenant-info-logins')}
            >
              Histórico de login
            </SubMenuButton>

            <SubMenuButton
              focus
              type="button"
              onClick={() => historys.push('/tenant/tenant-info-historys')}
            >
              Histórico de alterações
            </SubMenuButton>
          </div>

          <div className="search">
            <InputText
              name="month"
              type="month"
              value={month}
              onChange={event => setMonth(event.target.value)}
              lang="pt-br"
              required
            />

            <InputText
              name="searchHistory"
              placeholder="Encontre por Conta"
              value={search}
              onChange={event => setSearch(event.target.value)}
            />
          </div>

          {loading ? (
            <div className="loading">
              <CircularProgress style={{ color: 'var(--color-secondary)' }} />
            </div>
          ) : (
            <ul>
              {filteredHistorys.map(history => (
                <History
                  key={history.id}
                  history={history}
                  onDelete={() => handleDelete(history.id)}
                />
              ))}
              {histories.length === 0 && <p>Nenhuma alteração encontrada</p>}
            </ul>
          )}
        </Scroll>
      </Main>

      {logout && <UserLogout message={message} />}
    </Container>
  );
}
