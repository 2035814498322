import styled from 'styled-components';

export const Container = styled.div`
  padding: 0.5rem 2rem;
  border-radius: 0.4rem;
  border-left: 0.1rem solid var(--color-white);
  background: var(--color-primary);

  &:hover {
    opacity: var(--opacity-semiOpaque);
  }

  div.title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    color: var(--color-white);
    opacity: var(--opacity-semiOpaque);

    button {
      border: 0;
      background: transparent;
      color: var(--color-white);
    }
  }

  hr {
    border: 0;
    height: 0.1rem;
    background-color: var(--color-tertiary);

    margin: 1rem 0;
  }

  div.date {
    display: flex;
    justify-content: flex-end;

    padding-top: 1rem;

    span {
      font-size: var(--fontSizes-xl);
      opacity: var(--opacity-medium);
      color: var(--color-white);
    }
  }
`;

export const Content = styled.div`
  display: block;

  div {
    span {
      font-size: var(--fontSizes-2xl);
      opacity: var(--opacity-intense);
      color: var(--color-white);
    }
  }
`;

export const Header = styled.div`
  padding-bottom: 1rem;

  div.group {
    display: flex;
    align-items: center;
    justify-content: space-between;

    div {
      strong {
        display: flex;
        max-width: 22ch;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        color: var(--color-white);
      }
    }
  }

  @media (min-width: 1180px) {
    div.group {
      div {
        strong {
          max-width: 50ch;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
`;
