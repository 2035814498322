import styled from 'styled-components';

export const Titles = styled.div`
  margin-bottom: 2rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  white-space: nowrap;

  strong {
    font-size: var(--fontSizes-6xl);
  }

  span {
    font-size: var(--fontSizes-6xl);
    color: var(--color-primary);
  }

  @media (min-width: 1180px) {
    margin-bottom: 4rem;

    strong {
      margin-top: 0;
    }
  }
`;
