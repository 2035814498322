import styled from 'styled-components';

export const Container = styled.div`
  padding: 0 2rem;
  width: 100%;
  background: var(--color-primary);
  box-shadow: rgb(12 12 14 / 60%) 0 0.5rem 2rem;

  position: fixed;
  z-index: 2;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 6.4rem;

  nav {
    display: flex;
    align-items: center;

    a {
      display: flex;
      align-items: center;

      img {
        height: 4rem;
        width: 4rem;

        padding-right: 1rem;
      }

      strong {
        padding-right: 1rem;
        color: var(--color-white);
      }
    }

    strong.home {
      display: none;
    }

    button {
      display: flex;
      align-items: center;

      border-style: initial;
      border-color: initial;
      border-image: initial;
      padding-left: 2rem;

      background: none;
      color: var(--color-white);
      transition: color 0.2s ease 0s;

      svg {
        font-size: var(--fontSizes-4xl);
      }
    }
  }

  aside {
    display: flex;
    align-items: center;
  }

  @media (min-width: 1180px) {
    nav {
      img {
        height: 5rem;
        width: 5rem;
      }

      strong {
        font-size: var(--fontSizes-4xl);
      }

      strong.home {
        display: unset;
        padding-left: 1rem;

        font-size: var(--fontSizes-2xl);
        color: var(--color-white);
      }

      button {
        padding-left: 4rem;

        &:hover {
          opacity: var(--opacity-semiOpaque);
        }
      }
    }
  }
`;

export const Profile = styled.div`
  a {
    display: flex;
    align-items: center;
    margin-left: 2rem;

    div.title {
      margin-right: 1rem;
      text-align: right;

      strong {
        display: none;
      }
    }
  }

  img {
    width: 4.6rem;
    height: 4.6rem;
    border-radius: 50%;
    border: 0.2rem solid var(--color-primary);
    background: var(--color-primary);

    object-fit: cover;
  }

  div.photo {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 4.8rem;
    width: 4.8rem;
    border-radius: 50%;
    background: var(--color-white);
  }

  @media (min-width: 1180px) {
    a {
      margin-left: 5rem;
      color: var(--color-white);

      div.title {
        strong {
          display: unset;
          font-size: var(--fontSizes-2xl);
        }
      }

      &:hover {
        opacity: var(--opacity-semiOpaque);
      }
    }
  }
`;
