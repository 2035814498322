import styled from 'styled-components';

export const Content = styled.div`
  padding: 4rem 2rem 2rem 2rem;
  border-radius: 0.4rem;
  border: none;

  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const DialogTitle = styled.div`
  display: flex;
  align-items: center;

  strong {
    font-size: var(--fontSizes-6xl);
    color: var(--color-white);
  }
`;

export const DialogMessages = styled.div`
  border-left: 0.2rem solid var(--color-background);
  border-right: 0.2rem solid var(--color-background);

  margin-top: 4rem;
  margin-bottom: 3rem;

  p {
    display: flex;
    text-align: center;

    padding: 0 2rem;

    color: var(--color-yellow);
  }
`;

export const DialogButton = styled.div`
  width: 100%;
`;
