import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Form } from '@rocketseat/unform';
import * as Yup from 'yup';

import { Aside, Container, Main } from '~/components/Body';
import InputText from '~/components/InputText';
import SubmitButton from '~/components/SubmitButton';
import Title from '~/components/Title';
import { updateProfileRequest } from '~/store/modules/user/actions';
import { cpfMask, phoneMask } from '~/utils/inputMask';

import Menu from '../Menu';
import PhotoInput from '../PhotoInput';
import { Content } from './styles';

const schema = Yup.object().shape({
  name: Yup.string()
    .min(10, 'Nome completo deve ter no mínimo 10 caracteres')
    .max(50, 'Nome completo deve ter no máximo 50 caracteres'),
  nameuser: Yup.string()
    .min(3, 'Nome de usuário deve ter no mínimo 3 caracteres')
    .max(20, 'Nome de usuário deve ter no máximo 20 caracteres'),
  email: Yup.string()
    .email('Insira um e-mail válido')
    .min(7, 'E-mail deve ter no mínimo 7 caracteres')
    .max(50, 'E-mail deve ter no máximo 50 caracteres'),
  document: Yup.string().length(14, 'CPF deve ser preenchido apenas números'),
  phone: Yup.string().length(16, 'Celular deve ser preenchido apenas números'),
  photo: Yup.string(),
});

export default function Profile() {
  const dispatch = useDispatch();

  const profile = useSelector(state => state.user.profile);

  const history = useHistory();

  const [document, setDocument] = useState(profile.document);
  const [phone, setPhone] = useState(profile.phone);
  const [nameuser, setNameuser] = useState(profile.nameuser);

  function handleSubmit({ name, email, photo }) {
    dispatch(
      updateProfileRequest({
        name,
        nameuser,
        email,
        document,
        phone,
        photo,
      })
    );
  }

  return (
    <Container>
      <Aside>
        <Menu profile="profile" />
      </Aside>

      <Main>
        <Title title="Meu perfil" />

        <Content>
          <Form schema={schema} initialData={profile} onSubmit={handleSubmit}>
            <aside>
              <div>
                <PhotoInput name="photo" />
              </div>
            </aside>

            <div className="group">
              <div className="name">
                <InputText label="Nome completo" name="name" />
              </div>

              <div className="nameuser">
                <InputText
                  label="Nome de usuário"
                  name="nameusers"
                  value={nameuser}
                  onChange={event =>
                    setNameuser(
                      event.target.value.toLowerCase().replace(/\s+/g, '')
                    )
                  }
                />
              </div>
            </div>

            <div className="group">
              <div className="email">
                <InputText label="E-mail" name="email" type="email" />
              </div>

              <div className="document">
                <InputText
                  label="CPF"
                  name="documents"
                  value={document}
                  onChange={event => setDocument(cpfMask(event.target.value))}
                />
              </div>
            </div>

            <div className="group">
              <div className="phone">
                <InputText
                  label="Celular"
                  name="phones"
                  value={phone}
                  onChange={event => setPhone(phoneMask(event.target.value))}
                />
              </div>
            </div>

            <div className="group">
              <div>
                <SubmitButton type="submit">Salvar</SubmitButton>
              </div>

              <div className="cancel">
                <SubmitButton type="button" onClick={() => history.push('/')}>
                  Cancelar
                </SubmitButton>
              </div>
            </div>
          </Form>
        </Content>
      </Main>
    </Container>
  );
}
