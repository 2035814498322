import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 4rem;

  display: flex;
  flex-direction: column;

  @media (min-width: 1180px) {
    max-width: 100rem;
    margin: 4rem auto 0;
    padding: 4rem 0;

    display: flex;
    flex-direction: row;

    flex-wrap: nowrap;
  }
`;

export const Aside = styled.aside`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;

  ::-webkit-scrollbar {
    display: none;
  }

  margin: 1rem 0.5rem;
  border-bottom: 0.1rem solid var(--color-primary);

  @media (min-width: 1180px) {
    border-bottom: none;
    border-right: 0.1rem solid var(--color-primary);

    flex-direction: column;
    overflow-y: scroll;
  }
`;

export const Main = styled.main`
  padding: 1rem;

  @media (min-width: 1180px) {
    flex: 1;
    margin-left: 3rem;
  }
`;
