import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import CircularProgress from '@material-ui/core/CircularProgress';
import { Form } from '@rocketseat/unform';
import Swal from 'sweetalert2/src/sweetalert2';
import * as Yup from 'yup';

import { Aside, Container, Main } from '~/components/Body';
import InputText from '~/components/InputText';
import SubmitButton from '~/components/SubmitButton';
import Title from '~/components/Title';
import UserLogout from '~/components/UserLogout';
import api from '~/services/api';
import { ToastError, ToastSuccess } from '~/utils/toast';

import Menu from '../../Menu';
import { Content } from './styles';

const schema = Yup.object().shape({
  version: Yup.string()
    .min(3, 'Decrição deve ter no mínimo 3 caracteres')
    .max(50, 'Decrição deve ter no máximo 50 caracteres'),
  change: Yup.string()
    .min(10, 'Alterações deve ter no mínimo 10 caracteres')
    .max(1200, 'Alterações deve ter no máximo 1200 caracteres'),
  link: Yup.string().max(254, 'URL YouTube deve ter no máximo 254 caracteres'),
});

export default function ChangelogUpdate() {
  const id = useSelector(state => state.changelog.changelogId);

  const [loading, setLoading] = useState(false);
  const [logout, setLogout] = useState(false);
  const [message, setMessage] = useState('');

  const history = useHistory();

  const [changelogs, setChangelogs] = useState([]);

  try {
    useEffect(() => {
      async function loadChangelogData() {
        const response = await api.get(`/admin/changelogs/${id}`);

        setChangelogs(response.data);
      }

      loadChangelogData();
    }, [id]);
  } catch (error) {
    ToastError({ title: 'Erro ao carregar os dados' });
  }

  async function handleSubmit({ change, version, link }) {
    try {
      setLoading(true);

      const data = { version, change, link };

      const dataResponse = await api
        .put(`/admin/changelogs/${id}`, data)
        .catch(async res => {
          try {
            if (res.response.data.error === 'Validation fails') {
              ToastError({
                title:
                  'Seus dados foram recusados. Verifique-os e tente novamente',
              });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'User without permission') {
              setMessage(
                'Você não possui permissão para este tipo de operação'
              );

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Token not provided') {
              setMessage('Autenticação falhou, refaça seu login');

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Token invalid') {
              setMessage('Autenticação inválida, refaça seu login');

              setLogout(true);

              return;
            }
          } catch (error) {
            ToastError({ title: 'Erro ao alterar, verifique os dados' });

            setLoading(false);
          }
        });

      if (dataResponse === undefined) return;

      ToastSuccess({ title: 'Changelog alterado' });

      history.push('/news/changelog-list');
    } catch (error) {
      setLoading(false);

      ToastError({ title: 'Erro ao alterar, verifique os dados' });
    }
  }

  async function handleDelete() {
    Swal.fire({
      title: 'Você tem certeza?',
      text: 'Você não poderá reverter isso!',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: 'var(--color-blue)',
      cancelButtonColor: 'var(--color-red)',
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar',
    }).then(async result => {
      if (result.value) {
        try {
          const dataResponse = await api
            .delete(`/admin/changelogs/${id}`)
            .catch(async res => {
              try {
                if (res.response.data.error === 'User without permission') {
                  setMessage(
                    'Você não possui permissão para este tipo de operação'
                  );

                  setLogout(true);

                  return;
                }

                if (res.response.data.error === 'Token not provided') {
                  setMessage('Autenticação falhou, refaça seu login');

                  setLogout(true);

                  return;
                }

                if (res.response.data.error === 'Token invalid') {
                  setMessage('Autenticação inválida, refaça seu login');

                  setLogout(true);

                  return;
                }
              } catch (error) {
                ToastError({ title: 'Erro ao excluir' });

                setLoading(false);
              }
            });

          if (dataResponse === undefined) return;

          Swal.fire('Excluído!', 'Changelog excluído', 'success');

          window.history.back();
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Algo deu errado!',
          });
        }
      }
    });
  }

  return (
    <Container>
      <Aside>
        <Menu />
      </Aside>

      <Main>
        <Title title="Alterar changelog" />

        <Content>
          <Form
            schema={schema}
            initialData={changelogs}
            onSubmit={handleSubmit}
          >
            <div className="version">
              <InputText label="Descrição" name="version" />
            </div>

            <div className="link">
              <InputText label="URL YouTube" name="link" />
            </div>

            <div className="change">
              <InputText label="Alterações" name="change" multiline />
            </div>

            <button
              type="button"
              className="delete"
              onClick={() => handleDelete()}
            >
              Excluir changelog
            </button>

            <div className="group">
              <div>
                <SubmitButton type="submit" disabled={loading}>
                  {loading ? (
                    <CircularProgress
                      size={24}
                      style={{ color: 'var(--color-white)' }}
                    />
                  ) : (
                    'Salvar'
                  )}
                </SubmitButton>
              </div>

              <div className="cancel">
                <SubmitButton
                  type="button"
                  onClick={() => history.push('/news/changelog-list')}
                >
                  Cancelar
                </SubmitButton>
              </div>
            </div>
          </Form>
        </Content>
      </Main>

      {logout && <UserLogout message={message} />}
    </Container>
  );
}
