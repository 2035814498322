import React, { useEffect, useState } from 'react';
import { MdAttachMoney, MdCloudDone, MdGroup, MdPhoto } from 'react-icons/md';
import { useSelector } from 'react-redux';

import CircularProgress from '@material-ui/core/CircularProgress';

import { Aside, Container, Main } from '~/components/Body';
import Tenant from '~/components/Home';
import InfoCount from '~/components/InfoCount';
import Title from '~/components/Title';
import UserLogout from '~/components/UserLogout';
import api from '~/services/api';
import { formatPrice } from '~/utils/format';
import { ToastError } from '~/utils/toast';

import Menu from '../Menu';
import { DivInfoCount, Header, Scroll } from './styles';

export default function Home() {
  const { name, permission } = useSelector(state => state.user.profile);

  const [loading, setLoading] = useState(true);
  const [logout, setLogout] = useState(false);
  const [message, setMessage] = useState('');

  const [tenants, setTenants] = useState([]);

  const [usersCount, setUsersCount] = useState();
  const [photosCount, setPhotosCount] = useState();

  const tenantsPrices = tenants.map(tenant => tenant.price * tenant.people);
  const tenantsPricesTotal = tenantsPrices.reduce(
    (previousValue, currentValue) => previousValue + currentValue,
    0
  );

  useEffect(() => {
    async function loadCount() {
      const response = await api.get('/admin/count');

      setUsersCount(response.data[0]);
      setPhotosCount(response.data[1]);
    }

    loadCount();
  }, []);

  try {
    useEffect(() => {
      async function loadTenants() {
        const response = await api
          .get('/admin/tenants-actives')
          .catch(async res => {
            try {
              if (res.response.data.error === 'User disabled') {
                setMessage(
                  'Autenticação falhou. Entre em contato com seu administrador'
                );

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Token not provided') {
                setMessage('Autenticação falhou, refaça seu login');

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Token invalid') {
                setMessage('Autenticação inválida, refaça seu login');

                setLogout(true);

                return;
              }
            } catch (error) {
              ToastError({ title: 'Erro ao carregar os dados' });

              setLoading(false);
            }
          });

        if (response === undefined) {
          setLoading(false);

          return;
        }

        setTenants(response.data);

        setLoading(false);
      }

      loadTenants();
    }, []);
  } catch (error) {
    ToastError({ title: 'Erro ao carregar os dados' });
  }

  return (
    <Container>
      <Aside>
        <Menu home="home" />
      </Aside>

      <Main>
        <Title title={`Olá, ${name}`} />

        <Header>
          <span>É bom ter você de volta.</span>
        </Header>

        {permission && (
          <DivInfoCount>
            <InfoCount
              count={tenants.length}
              icon={<MdCloudDone size={20} />}
            />

            <InfoCount
              count={formatPrice(tenantsPricesTotal)}
              icon={<MdAttachMoney size={20} />}
            />

            <InfoCount count={usersCount || 0} icon={<MdGroup size={20} />} />

            <InfoCount count={photosCount || 0} icon={<MdPhoto size={20} />} />
          </DivInfoCount>
        )}

        <Scroll>
          {loading ? (
            <div className="loading">
              <CircularProgress style={{ color: 'var(--color-secondary)' }} />
            </div>
          ) : (
            <ul className="ul">
              {tenants.map(tenant => (
                <Tenant key={tenant.id} tenant={tenant} />
              ))}
              {tenants.length === 0 && <p>Nenhuma conta encontrada</p>}
            </ul>
          )}
        </Scroll>
      </Main>

      {logout && <UserLogout message={message} />}
    </Container>
  );
}
