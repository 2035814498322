import React from 'react';
import {
  MdAdd,
  MdCloud,
  MdCloudOff,
  MdCloudQueue,
  MdHome,
  MdInfo,
} from 'react-icons/md';
import { useSelector } from 'react-redux';

import MenuButton from '~/components/MenuButton';

import { Content } from './styles';

export default function Menu({
  tenantActived,
  tenantDisabled,
  tenantCanceled,
  tenantNew,
  tenantInfo,
}) {
  const { permission } = useSelector(state => state.user.profile);

  return (
    <Content permission={!permission}>
      <div>
        <MenuButton to="/dashboard">
          <MdHome size={18} /> Home
        </MenuButton>

        <MenuButton to="/tenant/tenant-active" focus={tenantActived}>
          <MdCloud size={18} /> Ativas
        </MenuButton>

        <MenuButton
          className="permission"
          to="/tenant/tenant-disabled"
          focus={tenantDisabled}
        >
          <MdCloudQueue size={18} /> Desativadas
        </MenuButton>

        <MenuButton
          className="permission"
          to="/tenant/tenant-canceled"
          focus={tenantCanceled}
        >
          <MdCloudOff size={18} /> Canceladas
        </MenuButton>

        <MenuButton to="/tenant/tenant-new" focus={tenantNew}>
          <MdAdd size={18} /> Adicionar
        </MenuButton>

        <MenuButton to="/tenant/tenant-info-logins" focus={tenantInfo}>
          <MdInfo size={18} /> Informações
        </MenuButton>
      </div>
    </Content>
  );
}
