import React from 'react';
import ReactMarkdown from 'react-markdown';

import { Container } from './styles';

export default function Markdown({ data }) {
  return (
    <Container>
      <ReactMarkdown>{data}</ReactMarkdown>
    </Container>
  );
}
