import styled from 'styled-components';

export const Content = styled.div`
  padding: 2rem;
  border-radius: 0.4rem;
  background: var(--color-primary);

  form {
    div.version,
    div.link,
    div.change {
      margin-bottom: 1rem;
    }

    div.group {
      display: block;
      padding-bottom: 1rem;

      div {
        width: 100%;
      }

      div.cancel {
        margin-left: 0;

        button {
          background: var(--color-red);
          &:hover {
            opacity: var(--opacity-semiOpaque);
          }
        }
      }
    }

    button.delete {
      margin-top: 0;

      border: none;
      background: none;
      box-shadow: rgb(232 241 242 / 10%) 0 0.5rem 2rem;
      color: var(--color-red);
      font-weight: bold;

      &:hover {
        opacity: var(--opacity-semiOpaque);
      }
    }
  }

  @media (min-width: 1180px) {
    form {
      div.group {
        display: flex;
        padding-bottom: 0;
      }

      div.cancel {
        display: flex;
        justify-content: flex-end;

        button {
          width: 50%;
        }
      }
    }
  }
`;
