import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Form } from '@rocketseat/unform';
import * as Yup from 'yup';

import { Aside, Container, Main } from '~/components/Body';
import InputText from '~/components/InputText';
import SubmitButton from '~/components/SubmitButton';
import Title from '~/components/Title';
import { updatePasswordRequest } from '~/store/modules/user/actions';

import Menu from '../Menu';
import { Content } from './styles';

const schema = Yup.object().shape({
  oldPassword: Yup.string()
    .min(4, 'Senha atual deve ter no mínimo 4 caracteres')
    .max(20, 'Senha atual deve ter no máximo 20 caracteres'),
  password: Yup.string()
    .min(4, 'Nova senha deve ter no mínimo 4 caracteres')
    .max(20, 'Nova senha deve ter no máximo 20 caracteres'),
  confirmPassword: Yup.string()
    .min(4, 'Confirme sua senha deve ter no mínimo 4 caracteres')
    .max(20, 'Confirme sua senha deve ter no máximo 20 caracteres')
    .oneOf([Yup.ref('password'), null], 'Senhas não conferem'),
});

export default function Password() {
  const dispatch = useDispatch();

  const profile = useSelector(state => state.user.profile);

  const history = useHistory();

  function handleSubmit(oldPassword, password, confirmPassword) {
    dispatch(updatePasswordRequest(oldPassword, password, confirmPassword));
  }

  return (
    <Container>
      <Aside>
        <Menu password="password" />
      </Aside>

      <Main>
        <Title title="Senha secreta" />

        <Content>
          <Form schema={schema} initialData={profile} onSubmit={handleSubmit}>
            <div className="group">
              <div>
                <InputText
                  label="Senha atual"
                  name="oldPassword"
                  type="password"
                  placeholder="****"
                  autoComplete="cc-csc"
                />
              </div>

              <div>
                <InputText
                  label="Nova senha"
                  name="password"
                  type="password"
                  placeholder="****"
                  autoComplete="cc-csc"
                />
              </div>

              <div>
                <InputText
                  label="Confirme sua senha"
                  name="confirmPassword"
                  type="password"
                  placeholder="****"
                  autoComplete="cc-csc"
                />
              </div>
            </div>

            <div className="group-button">
              <div>
                <SubmitButton type="submit">Salvar</SubmitButton>
              </div>

              <div className="cancel">
                <SubmitButton
                  type="button"
                  onClick={() => history.push('/account/profile')}
                >
                  Cancelar
                </SubmitButton>
              </div>
            </div>
          </Form>
        </Content>
      </Main>
    </Container>
  );
}
