import React, { useEffect, useState } from 'react';
import { MdAdd, MdExpandLess, MdExpandMore, MdRemove } from 'react-icons/md';
import { useSelector } from 'react-redux';
import Switch from 'react-switch';

import CircularProgress from '@material-ui/core/CircularProgress';
import { Form } from '@rocketseat/unform';
import axios from 'axios';
import { format, parseISO } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import Swal from 'sweetalert2/src/sweetalert2';
import * as Yup from 'yup';

import { Aside, Container, Main } from '~/components/Body';
import InputText from '~/components/InputText';
import SubmitButton from '~/components/SubmitButton';
import Title from '~/components/Title';
import UserLogout from '~/components/UserLogout';
import api from '~/services/api';
import { cep, cnpjMask } from '~/utils/inputMask';
import { ToastError, ToastSuccess } from '~/utils/toast';

import Menu from '../Menu';
import { Content, Label, User } from './styles';

const schema = Yup.object().shape({
  name: Yup.string()
    .min(10, 'Nome deve ter no mínimo 10 caracteres')
    .max(100, 'Nome deve ter no máximo 100 caracteres'),
  nickname: Yup.string()
    .min(3, 'Nome deve ter no mínimo 3 caracteres')
    .max(20, 'Nome deve ter no máximo 20 caracteres'),
  document: Yup.string().length(18, 'CNPJ dever ser preenchido apenas números'),
  people: Yup.number()
    .min(5, 'Limite mínimo de pessoas 5')
    .typeError('Limite de pessoas é obrigatório'),
  price: Yup.number()
    .min(0, 'Valor mínimo por pessoa R$ 0,00')
    .typeError('Valor por pessoa é obrigatório'),
  available: Yup.date().required('Data de expiração é obrigatório'),
  active: Yup.boolean(),
  canceled: Yup.boolean(),
  zip_code: Yup.string(),
  number: Yup.string(),
  complement: Yup.string(),
  pro: Yup.boolean(),
  beta: Yup.boolean(),
});

export default function TenantUpdate() {
  const { email } = useSelector(state => state.user.profile);

  const id = useSelector(state => state.tenant.tenantId);

  const [loading, setLoading] = useState(false);
  const [logout, setLogout] = useState(false);
  const [message, setMessage] = useState('');

  const [tenants, setTenants] = useState([]);
  const [cnpj, setCNPJ] = useState([]);
  const [actives, setActives] = useState(false);
  const [canceleds, setCanceleds] = useState(false);
  const [zip_code, setZip_code] = useState([]);
  const [street, setStreet] = useState([]);
  const [district, setDistrict] = useState([]);
  const [city, setCity] = useState([]);
  const [state, setState] = useState([]);

  const [pros, setPros] = useState(false);
  const [betas, setBetas] = useState(false);

  const [users, setUsers] = useState([]);
  const [newUser, setNewUser] = useState(false);
  const [newEmail, setNewEmail] = useState();
  const [updateUser, setUpdateUser] = useState(false);

  try {
    useEffect(() => {
      async function loadTenantData() {
        const response = await api.get(`/admin/tenants/${id}`);

        const {
          name,
          nickname,
          document,
          people,
          price,
          available,
          active,
          canceled,
          created_at,
          updated_at,
          number,
          complement,
          pro,
          beta,
        } = response.data;

        const {
          name: nameResponsible,
          phone,
          email: emailResponsible,
          document: documentResponsible,
        } = response.data.user;

        const parsedAvailable = parseISO(available);
        const parsedCreate = parseISO(created_at);
        const parsedUpdate = parseISO(updated_at);

        const formattedAvailable = format(parsedAvailable, 'yyyy-MM-dd');
        const formattedCreate = format(parsedCreate, 'yyyy-MM-dd');
        const formattedUpdate = format(parsedUpdate, 'yyyy-MM-dd');

        const data = {
          name,
          nickname,
          document,
          people,
          price,
          available: formattedAvailable,
          active,
          canceled,
          created_at: formattedCreate,
          updated_at: formattedUpdate,
          number,
          complement,
          pro,
          beta,
          responsible: nameResponsible,
          phone,
          email: emailResponsible,
          document_responsible: documentResponsible,
        };

        setTenants(data);
        setCNPJ(data.document);
        setActives(data.active);
        setCanceleds(data.canceled);
        setZip_code(
          response.data.zip_code === null ? '' : response.data.zip_code
        );

        setPros(data.pro);
        setBetas(data.beta);
      }

      loadTenantData();
    }, [id]);
  } catch (error) {
    ToastError({ title: 'Erro ao carregar os dados' });
  }

  useEffect(() => {
    async function cepUser() {
      if (zip_code.length === 9) {
        const response = await axios.get(
          `https://viacep.com.br/ws/${zip_code.replace(/\D/g, '')}/json/`
        );

        const { logradouro, bairro, localidade, uf } = response.data;

        setStreet(logradouro);
        setDistrict(bairro);
        setCity(localidade);
        setState(uf);
      }
    }

    cepUser();
  }, [zip_code]);

  useEffect(() => {
    async function loadUserAccount() {
      const response = await api.get('/admin/accounts-shared', {
        params: { id },
      });

      setUsers(response.data);
    }

    loadUserAccount();
  }, [id, updateUser]);

  function handleActive() {
    if (actives === true) {
      setActives(false);
    }

    if (actives === false) {
      setActives(true);

      setCanceleds(false);
    }
  }

  function handleCanceled() {
    if (canceleds === true) {
      setCanceleds(false);
    }

    if (canceleds === false) {
      setCanceleds(true);

      setActives(false);
    }
  }

  async function handleSubmit({
    name,
    nickname,
    document,
    people,
    price,
    available,
    number,
    complement,
  }) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const timezoneDate = zonedTimeToUtc(available, timezone);

    const nicknameTrim = nickname.trim();

    try {
      setLoading(true);

      const data = {
        name,
        nickname: nicknameTrim,
        document,
        people,
        price,
        available: timezoneDate,
        active: actives,
        canceled: canceleds,
        zip_code,
        number,
        complement,
        pro: pros,
        beta: betas,
      };

      const dataResponse = await api
        .put(`/admin/tenants/${id}`, data)
        .catch(async res => {
          try {
            if (res.response.data.error === 'Validation fails') {
              ToastError({
                title:
                  'Seus dados foram recusados. Verifique-os e tente novamente',
              });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'User without permission') {
              setMessage(
                'Você não possui permissão para este tipo de operação'
              );

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'CNPJ is invalid') {
              ToastError({ title: 'CNPJ é inválido' });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'Nickname already exists') {
              ToastError({ title: 'Conta já existe' });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'Number of peoples reached') {
              ToastError({
                title:
                  'Quantidade de pessoas ativas é maior que a quantidade informada',
              });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'Tenant expired') {
              ToastError({
                title: 'Plano Pro expirado',
              });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'Token not provided') {
              setMessage('Autenticação falhou, refaça seu login');

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Token invalid') {
              setMessage('Autenticação inválida, refaça seu login');

              setLogout(true);

              return;
            }
          } catch (error) {
            ToastError({ title: 'Erro ao alterar, verifique os dados' });

            setLoading(false);
          }
        });

      if (dataResponse === undefined) return;

      ToastSuccess({ title: 'Conta alterada' });

      window.history.back();
    } catch (error) {
      setLoading(false);

      ToastError({ title: 'Erro ao alterar, verifique os dados' });
    }
  }

  async function handleDelete() {
    Swal.fire({
      title: 'Você tem certeza?',
      text: 'Você não poderá reverter isso!',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: 'var(--color-blue)',
      cancelButtonColor: 'var(--color-red)',
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar',
    }).then(async result => {
      if (result.value) {
        try {
          const dataResponse = await api
            .delete(`/admin/tenants/${id}`)
            .catch(async res => {
              try {
                if (res.response.data.error === 'User without permission') {
                  setMessage(
                    'Você não possui permissão para este tipo de operação'
                  );

                  setLogout(true);

                  return;
                }

                if (res.response.data.error === 'Tenant not canceled') {
                  ToastError({ title: 'Está conta ainda não está cancelada' });

                  setLoading(false);

                  return;
                }

                if (res.response.data.error === 'Tenant has not yet expired') {
                  ToastError({ title: 'Está conta ainda não expirou' });

                  setLoading(false);

                  return;
                }

                if (res.response.data.error === 'Token not provided') {
                  setMessage('Autenticação falhou, refaça seu login');

                  setLogout(true);

                  return;
                }

                if (res.response.data.error === 'Token invalid') {
                  setMessage('Autenticação inválida, refaça seu login');

                  setLogout(true);

                  return;
                }
              } catch (error) {
                ToastError({ title: 'Erro ao excluir' });

                setLoading(false);
              }
            });

          if (dataResponse === undefined) return;

          Swal.fire('Excluída!', 'Conta excluída', 'success');

          window.history.back();
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Algo deu errado!',
          });
        }
      }
    });
  }

  async function handleNewUser() {
    try {
      const data = await api
        .post('admin/account-share', {
          id,
          email: newEmail,
        })
        .catch(async res => {
          try {
            if (res.response.data.error === 'Validation fails') {
              ToastError({
                title:
                  'Seus dados foram recusados. Verifique-os e tente novamente',
              });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'User without permission') {
              setMessage(
                'Você não possui permissão para este tipo de operação'
              );

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'User not found') {
              ToastError({ title: 'Usuário não encontrado' });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'User disabled') {
              ToastError({ title: 'Usuário desativado' });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'User already has access') {
              ToastError({ title: 'Usuário já tem acesso' });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'Tenant disabled') {
              ToastError({
                title: 'Conta desativada',
              });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'Tenant expired') {
              ToastError({
                title: 'Plano Pro expirado',
              });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'Id already exists') {
              ToastError({ title: 'Recarregue a pagina e tente novamente' });

              setLoading(false);

              return;
            }
          } catch (error) {
            ToastError({ title: 'Erro ao adicionar, verifique os dados' });

            setLoading(false);
          }
        });

      if (data === undefined) return;

      ToastSuccess({ title: 'Conta campartilhada' });

      setNewUser(false);
      setUpdateUser(!updateUser);
    } catch (error) {
      setLoading(false);

      ToastError({ title: 'Erro ao compartilhar, verifique os dados' });
    }
  }

  async function handleRemoveUser(idAccountShare) {
    Swal.fire({
      title: 'Você tem certeza?',
      text: 'Você não poderá reverter isso!',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: 'var(--color-blue)',
      cancelButtonColor: 'var(--color-red)',
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar',
    }).then(async result => {
      if (result.value) {
        try {
          const dataResponse = await api
            .delete(`/admin/accounts-shared/${idAccountShare}`)
            .catch(async res => {
              try {
                if (res.response.data.error === 'User without permission') {
                  setMessage(
                    'Você não possui permissão para este tipo de operação'
                  );

                  setLogout(true);

                  return;
                }
              } catch (error) {
                ToastError({ title: 'Erro ao remover' });
              }
            });

          if (dataResponse === undefined) return;

          Swal.fire('Excluído!', 'Usuário excluído', 'success');

          setUsers(users.filter(user => user.id !== idAccountShare));
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Algo deu errado!',
          });
        }
      }
    });
  }

  return (
    <Container>
      <Aside>
        <Menu />
      </Aside>

      <Main>
        <Title title="Alterar cadastro" />

        <Content>
          <Form schema={schema} initialData={tenants} onSubmit={handleSubmit}>
            <div className="group">
              <div className="name">
                <InputText label="Nome" name="name" />
              </div>

              <div className="document">
                <InputText
                  label="CNPJ"
                  name="document"
                  value={cnpj}
                  onChange={event => setCNPJ(cnpjMask(event.target.value))}
                />
              </div>
            </div>

            <div className="group">
              <div className="people">
                <InputText
                  label="Limite de pessoas"
                  name="people"
                  type="number"
                />
              </div>

              <div className="price">
                <InputText
                  label="Valor por pessoa"
                  name="price"
                  type="number"
                  step=".01"
                />
              </div>

              <div className="nickname">
                <InputText label="Conta" name="nickname" />
              </div>
            </div>

            <div className="group">
              <div className="available">
                <InputText
                  label="Data de validade"
                  name="available"
                  type="date"
                />
              </div>

              <div className="created">
                <InputText
                  label="Cadastrado"
                  title="Data que foi cadastro"
                  className="disabled"
                  name="created_at"
                  type="date"
                  disabled
                />
              </div>

              <div className="updated">
                <InputText
                  label="Alterado"
                  title="Data que foi alterado"
                  className="disabled"
                  name="updated_at"
                  type="date"
                  disabled
                />
              </div>
            </div>

            <div className="group">
              <div className="zip_code">
                <InputText
                  label="CEP"
                  name="zip_code"
                  value={zip_code}
                  onChange={event => setZip_code(cep(event.target.value))}
                />
              </div>

              <div className="street">
                <InputText
                  label="Rua"
                  title="Rua preenchida automaticamente de acordo com o CEP"
                  name="street"
                  className="disabled"
                  value={street}
                  disabled
                />
              </div>
            </div>

            <div className="group">
              <div className="number">
                <InputText label="Número" name="number" type="number" />
              </div>

              <div className="complement">
                <InputText label="Complemento" name="complement" />
              </div>
            </div>

            <div className="group">
              <div className="district">
                <InputText
                  label="Bairro"
                  title="Bairro preenchido automaticamente de acordo com o CEP"
                  name="district"
                  className="disabled"
                  value={district}
                  disabled
                />
              </div>

              <div className="city">
                <InputText
                  label="Cidade"
                  title="Cidade preenchida automaticamente de acordo com o CEP"
                  name="city"
                  className="disabled"
                  value={city}
                  disabled
                />
              </div>

              <div className="state">
                <InputText
                  label="UF"
                  title="UF preenchido automaticamente de acordo com o CEP"
                  name="state"
                  className="disabled"
                  value={state}
                  disabled
                />
              </div>
            </div>

            <div className="group">
              <div className="responsible">
                <InputText
                  label="Responsável"
                  title="Nome do responsível pela conta"
                  className="disabled"
                  name="responsible"
                  disabled
                />
              </div>

              <div className="phone">
                <InputText
                  label="Celular"
                  title="Celular do responsível pela conta"
                  className="disabled"
                  name="phone"
                  disabled
                />
              </div>
            </div>

            <div className="group">
              <div className="email">
                <InputText
                  label="E-mail"
                  title="E-mail do responsível pela conta"
                  className="disabled"
                  name="email"
                  disabled
                />
              </div>

              <div className="document_responsible">
                <InputText
                  label="CPF"
                  title="CPF do responsível pela conta"
                  className="disabled"
                  name="document_responsible"
                  disabled
                />
              </div>
            </div>

            <div className="group-switch">
              <div>
                <Label>Ativar</Label>
                <div className="permission">
                  <Switch
                    height={15}
                    width={40}
                    handleDiameter={20}
                    onColor="#247BA0"
                    offColor="#13293D"
                    onChange={handleActive}
                    checked={actives}
                  />
                </div>
              </div>

              <div>
                <Label>Cancelar</Label>
                <div className="permission">
                  <Switch
                    height={15}
                    width={40}
                    handleDiameter={20}
                    onColor="#247BA0"
                    offColor="#13293D"
                    onChange={handleCanceled}
                    checked={canceleds}
                  />
                </div>
              </div>
            </div>

            <hr />

            <div className="group-switch">
              <div>
                <Label>Pro</Label>
                <div className="permission">
                  <Switch
                    height={15}
                    width={40}
                    handleDiameter={20}
                    onColor="#247BA0"
                    offColor="#13293D"
                    onChange={() => setPros(!pros)}
                    checked={pros}
                  />
                </div>
              </div>

              <div>
                <Label>Beta</Label>
                <div className="permission">
                  <Switch
                    height={15}
                    width={40}
                    handleDiameter={20}
                    onColor="#247BA0"
                    offColor="#13293D"
                    onChange={() => setBetas(!betas)}
                    checked={betas}
                  />
                </div>
              </div>
            </div>

            <hr />

            {email === 'admin@praises.com.br' && (
              <button
                type="button"
                className="delete"
                onClick={() => handleDelete()}
              >
                Excluir conta
              </button>
            )}

            <div className="group">
              <div>
                <SubmitButton type="submit" disabled={loading}>
                  {loading ? (
                    <CircularProgress
                      size={24}
                      style={{ color: 'var(--color-white)' }}
                    />
                  ) : (
                    'Salvar'
                  )}
                </SubmitButton>
              </div>

              <div className="cancel">
                <SubmitButton
                  type="button"
                  onClick={() => window.history.back()}
                >
                  Cancelar
                </SubmitButton>
              </div>
            </div>

            <hr className="hr-new-user" />

            <div className="div-input-new-user">
              <strong>Usuários da conta</strong>
              <button type="button" onClick={() => setNewUser(!newUser)}>
                {newUser === false ? (
                  <MdExpandMore size={30} />
                ) : (
                  <MdExpandLess size={30} />
                )}
              </button>
            </div>
          </Form>

          {newUser && (
            <div className="div-new-user">
              <InputText
                title="E-mail do novo usuário"
                name="new-email"
                type="email"
                placeholder="E-mail"
                onChange={event => setNewEmail(event.target.value)}
              />

              <button type="button" onClick={handleNewUser}>
                <MdAdd size={30} />
              </button>
            </div>
          )}

          {users.map(user => (
            <User
              key={user.user.id}
              style={{
                color:
                  user.user.supervisor === true ? 'var(--color-yellow' : {},
              }}
            >
              {user.user.email}{' '}
              <button type="button" onClick={() => handleRemoveUser(user.id)}>
                <MdRemove size={30} />
              </button>{' '}
            </User>
          ))}
          {users.length === 0 && <p>Nenhum usuário encontrado</p>}
        </Content>
      </Main>

      {logout && <UserLogout message={message} />}
    </Container>
  );
}
