import styled from 'styled-components';

export const Container = styled.div`
  align-self: center;
  margin-bottom: 3rem;

  label {
    cursor: pointer;
    opacity: var(--opacity-default);

    img {
      height: 12rem;
      width: 12rem;
      border-radius: 50%;
      border: 0.4rem solid var(--color-primary);
      background: var(--color-primary);

      object-fit: cover;
    }

    div {
      display: flex;
      align-items: center;
      justify-content: center;

      height: 12.4rem;
      width: 12.4rem;
      border-radius: 50%;
      background: var(--color-white);

      &:hover {
        opacity: var(--opacity-medium);
      }
    }

    input {
      display: none;
    }
  }
`;
