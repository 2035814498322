import React from 'react';
import { MdCloud, MdDelete } from 'react-icons/md';
import { useSelector } from 'react-redux';

import { formatDistance, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';

import photoDefault from '~/assets/photo-default.svg';

import { Container, Content, Header } from './styles';

export default function Feedback({ feedback, onDelete }) {
  const { email } = useSelector(state => state.user.profile);

  const formattedDate = formatDistance(
    parseISO(feedback.created_at),
    new Date(),
    {
      addSuffix: true,
      locale: pt,
    }
  );

  return (
    <Container pro={feedback.tenant.pro}>
      <div className="title">
        <div>
          <MdCloud size={18} />

          <strong>{feedback.tenant.nickname}</strong>
        </div>

        {email === 'admin@praises.com.br' && (
          <button type="button" onClick={onDelete}>
            <MdDelete size={18} />
          </button>
        )}
      </div>

      <hr />

      <Content>
        <Header>
          <div>
            <img
              src={feedback.user.photo ? feedback.user.url : photoDefault}
              alt=""
            />
          </div>

          <div className="group">
            <div>
              <strong>{feedback.user.name}</strong>
            </div>

            <div>
              <span>{feedback.description}</span>
            </div>
          </div>
        </Header>
      </Content>

      <div className="date">
        <span>{formattedDate}</span>
      </div>
    </Container>
  );
}
