import React from 'react';

import { Input } from '@rocketseat/unform';

import { Div } from './styles';

export default function InputText({ ...props }) {
  return (
    <Div>
      <Input {...props} />
    </Div>
  );
}
