import React from 'react';
import { MdAdd, MdAutorenew, MdHome } from 'react-icons/md';
import { useSelector } from 'react-redux';

import MenuButton from '~/components/MenuButton';

import { Content } from './styles';

export default function Menu({ changelog, changelogNew }) {
  const { permission } = useSelector(state => state.user.profile);

  return (
    <Content permission={!permission}>
      <div>
        <MenuButton to="/dashboard">
          <MdHome size={18} /> Home
        </MenuButton>

        <MenuButton to="/news/changelog-list" focus={changelog}>
          <MdAutorenew size={18} /> Changelog
        </MenuButton>

        <MenuButton
          className="permission"
          to="/news/changelog-new"
          focus={changelogNew}
        >
          <MdAdd size={18} /> Adicionar
        </MenuButton>
      </div>
    </Content>
  );
}
